import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    padding-top: 60px;

    h3 {
        font-family: 'Cooper Black';
        filter: drop-shadow(4px 3px 1px #000);
    }

    .btn-link {
        transition: all 0.04s;
        width: 100%;
    }

    .btn-link:active {
        transition: all 0.04s;
        transform: scale(1.1);
        opacity: 0.9;
    }

    .row-btn {
        height: 90%;
        padding: 20px;
    }

    .divider {
        min-height: 10px;
    }
`;
