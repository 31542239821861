import styled from "styled-components";
import backgroundTerms from "../../assets/background_terms.jpg";

export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundTerms});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 60px;
    font-family: 'Roboto Slab', serif;
    padding-bottom: 30px;

    .cras {
        margin: 0 15px;
        padding: 7%;
        color: white;
        text-align: justify;
        text-justify: inter-word;
        filter: drop-shadow(3px 3px 3px #333);
        background-color: rgba(51, 51, 51, 0.9);
        border-radius: 20px;
    }

    .img-header img {
        margin-top: 20px;
        max-width: 30%;
        margin-bottom: 20px;
    }

    .link {
        margin: 15px 15px 0 15px;
        padding: 15px;
    }
`;
