import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container } from "./styles";
import SimpleMenu from "../../components/Menu";
import { getUser } from "../../services/auth";

import btnGames from "../../assets/btn_games.png";
import btnChats from "../../assets/btn_chats.png";
import btnComplaints from "../../assets/btn_complaints.png";
import btnContacts from "../../assets/btn_contacts.png";
import btnLibraries from "../../assets/btn_libraries.png";
import backgroundLogin from "../../assets/background_home.jpg";
import backgroundLoginM from "../../assets/background_home-m.jpg";
import { url } from "../../services/api";

import api from "../../services/api";

class Home extends Component {

    constructor(props) {
        super(props);
        const user = JSON.parse(getUser());

        this.state = {
            user: user,
            salutation: user.gender === 'f' ? 'Bem Vinda' : 'Bem Vindo'
        }

        const token = localStorage.getItem("localToken");

        api.post(url + "/users/fcmToken/" + user.id, {"fcm_token": token}).then(response =>{
            console.warn("Token enviado: " + token);
            //console.log(response);
        })
        .catch(err=>{
            console.error("Erro ao enviar token");
        });
    }

    render() {
        const background = () => {
            if(this.state.user.gender === "f") {
                return backgroundLogin;
            } else {
                return backgroundLoginM;
            }
        }

        const styleDiv = {
            "backgroundImage": `url(${background()})`,
            "backgroundSize": "cover",
            "backgroundPosition": "center",
            "backgroundRepeat": "no-repeat",
            "maxHeight": "100vh",
            "overflow": "hidden"
        };
        return (
            <div style={styleDiv}>
                <SimpleMenu />
                <Container>
                    <div className="container">
                        <div className="row row-btn">
                            <div className="w-100 text-center text-white">
                                <h3>{this.state.salutation}</h3>
                                <h3>{this.state.user.name}</h3>
                            </div>
                            <div className="row align-items-end">
                                <div className="col-4">
                                    <Link to="/Games"><img src={btnGames} className="btn-link" alt="Jogos" /></Link>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                    <Link to="/Library"><img src={btnLibraries} className="btn-link" alt="Biblioteca" /></Link>
                                </div>
                                <div className="w-100 divider"></div>
                                <div className="col-4">
                                    <Link to="/ComplaintAnonymous"><img src={btnComplaints} className="btn-link" alt="Denúncia" /></Link>
                                </div>
                                <div className="col-4"></div>
                                <div className="col-4 w-100 float-right">
                                    <Link to="/Chat"><img src={btnChats} className="btn-link" alt="Chat" /></Link>
                                </div>
                                <div className="w-100 divider"></div>
                                <div className="col-4"></div>
                                <div className="col-4">
                                    <Link to="/Contact"><img src={btnContacts} className="btn-link" alt="Contato" /></Link>
                                </div>
                                <div className="col-4"></div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withRouter(Home);
