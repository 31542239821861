import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { UnsignedMenu } from "../../components/Menu";
import Boy from "../../assets/boy.png";
import Girl from "../../assets/girl.png";
import BtnSubmit from "../../assets/background_btn_submit.png";
import { FormStile, Container } from "./styles";
import api from "../../services/api";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";
import { setUser, login } from "../../services/auth";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import * as moment from 'moment';
import 'moment/locale/pt-br.js'
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';

class SignUp extends Component {
    state = {
        loading: false,
        username: "",
        email: "",
        name: "",
        password: "",
        gender: "",
        birth_date: "",
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        authorizes_chat: 0,
        error: "",
        acceptTerms: "",
        relativeDate: null
    };

    handleSignUp = async e => {
        this.setState({loading: true});
        ////console.log('dados: ', this.state);
        this.state.authorizes_chat = this.state.authorizes_chat ? 1 : 0;
        const { username, email, name, password, gender, birth_date, address, number, complement, neighborhood, city, authorizes_chat } = this.state;
        try {
            await api.post("/users/add", { username, email, name, password, gender, birth_date, address, number, complement, neighborhood, city, authorizes_chat }).then(res=>{
                ////console.log('retorno: ', res.data);
                setUser(JSON.stringify(res.data.user));
                login(res.data.data.token);
                this.setState({loading: false});
                this.props.history.push("/");
            }).catch(err=>{
                ////console.log('erro retorno: ', err.response.data);
                let msg_error = '';
                if (err.response.data.error.username) {
                    msg_error = "O Login " + username + " já existe, escolha outro e tente novamente.";
                }
                if (err.response.data.error.email) {
                    msg_error += msg_error !== '' ? '<br>' : '';
                    msg_error += "O e-mail " + email + " já está cadastrado."
                }
                if (msg_error === '') {
                    this.setState({error: "Não foi possível enviar os dados. Tente novamente."});
                } else {
                    this.setState({error: msg_error});
                }
                this.setState({loading: false});
                window.scrollTo(0, 0)
            });

        } catch (err) {
            ////console.log(err);
            this.setState({ error: "Ocorreu um erro ao registrar os dados." });
            this.setState({loading: false});
            window.scrollTo(0, 0)
        }
    };

    render() {
        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;
        return (
            <Formik
                initialValues={{
                    'username': this.state.username,
                    'name': this.state.name,
                    'email': this.state.email,
                    'gender': this.state.gender,
                    'birth_date': this.state.birth_date,
                    'password': '',
                    'passwordConfirmation': '',
                    'address': this.state.address,
                    'number': this.state.number,
                    'complement': this.state.component,
                    'neighborhood': this.state.neighborhood,
                    'city': this.state.city,
                    'authorizes_chat': false,
                    'acceptTerms': false
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string().required('Login Obrigatório'),
                    name: Yup.string().required('Nome Obrigatório'),
                    email: Yup.string().required('E-mail Obrigatório'),
                    gender: Yup.string().required('Gênero Obrigatório'),
                    password: Yup.string().required('Senha Obrigatória'),
                    birth_date: Yup.date().required('Data de Nascimento Obrigatória'),
                    acceptTerms: Yup.bool().oneOf([true], 'Obrigatório aceitar os Termos'),
                    passwordConfirmation: Yup.string().required('Confirmar Senha Obrigatório').oneOf([Yup.ref('password'), null], 'Senhas Diferentes')
                })}
                onSubmit={fields => {
                    ////console.log('Campos: ', fields);
                    this.setState({username: fields.username});
                    this.setState({email: fields.email});
                    this.setState({name: fields.name});
                    this.setState({password: fields.password});
                    this.setState({gender: fields.gender});
                    
                    this.setState({birth_date: moment(fields.birth_date).utc(false).format("YYYY-MM-DD")});
                    this.setState({address: fields.address});
                    this.setState({number: fields.number});
                    this.setState({complement: fields.complement});
                    this.setState({neighborhood: fields.neighborhood});
                    this.setState({city: fields.city});
                    this.setState({authorizes_chat: fields.authorizes_chat ? 1 : 0});

                    this.handleSignUp();
                }}
            >
                {({errors, status, touched, submitForm}) => (
                    <div>
                        <UnsignedMenu />
                        {this.state.loading && <div className="sweet-loading">
                            <PacmanLoader
                                css={override}
                                size={50}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>}
                        <Container>
                            <div className="container">
                                <FormStile onSubmit={this.handleSignUp}>
                                    {this.state.error && <p dangerouslySetInnerHTML={{__html: this.state.error}}></p>}
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <h3 className="title">Criar uma conta</h3>
                                            <hr />
                                            <Field
                                                name="username"
                                                type="text"
                                                placeholder="Nome de Usuário"
                                                className={'username background1 ' + (errors.username && touched.username ? ' is-invalid' : '')}
                                            />
                                            <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field
                                                name="name"
                                                type="text"
                                                placeholder="Seu Nome"
                                                className="name background1"
                                            />
                                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-12 text-center" style={{position: "relative"}}>
                                            <Field
                                                name="email"
                                                type="text"
                                                placeholder="E-mail"
                                                className="email background2"
                                            />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col text-center">
                                            <img src={Boy} className="boy" alt="Menino" />
                                            <div className="form-check">
                                                <Field type="radio" name="gender" id="gender3" value="m" className="form-check-input"
                                                    onClick={e => this.setState({ gender: e.target.value })}
                                                    checked={this.state.gender === 'm'}
                                                />
                                                <label className="form-check-label" htmlFor="gender3">
                                                    Menino
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col text-center">
                                            <img src={Girl} className="boy" alt="Menina" />
                                            <div className="form-check">
                                                <Field type="radio" name="gender" id="gender2" value="f" className="form-check-input" />
                                                <label className="form-check-label" htmlFor="gender2">
                                                    Menina
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <ErrorMessage name="gender" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <span className="birth_date label_birth_date">Data de Nascimento</span>
                                            <Field type="text" name="birth_date" id="birth_date" className="birth_date" value={this.state.birth_date}
                                               render={({field, form}) =>(
                                                   <DatePickerInput
                                                       onChange={e => form.setFieldValue('birth_date', e)}
                                                       value={this.state.birth_date}
                                                       className='birth_date'
                                                       locale='pt-br'
                                                       name='birth_date'
                                                       type="text"
                                                   />
                                               )}
                                            />
                                            <ErrorMessage name="birth_date" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field
                                                name="password"
                                                type="password"
                                                placeholder="Senha"
                                                className="password"
                                            />
                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field
                                                name="passwordConfirmation"
                                                type="password"
                                                placeholder="Confirma Senha"
                                                className="password"
                                            />
                                            <ErrorMessage name="passwordConfirmation" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field type="text" name="address" id="address" className="background1" placeholder="Rua / Avenida" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field type="text" name="number" id="number" className="background1" placeholder="Número" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field type="text" name="complement" id="complement" className="background1" placeholder="Complemento" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field type="text" name="neighborhood" id="neighborhood" className="background1" placeholder="Bairro" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <Field type="text" name="city" id="city" className="background1" placeholder="Cidade" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <div className="form-check">
                                                <Field
                                                    name="authorizes_chat"
                                                    id="authorizes_chat"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                />
                                                <label className="form-check-label" htmlFor="authorizes_chat">
                                                    Autorizo exibir meu Nome no Chat
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <div className="form-check">
                                            <Field
                                                name="acceptTerms"
                                                id="acceptTerms"
                                                type="checkbox"
                                                className="form-check-input"
                                            />
                                                <label className="form-check-label" htmlFor="acceptTerms">
                                                    Declaro que li e aceito os <Link to="/Terms" className="text-danger">Termos</Link> de uso
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <img src={BtnSubmit} className="btn" onClick={submitForm} alt="Salvar" />
                                        </div>
                                    </div>

                                </FormStile>
                            </div>
                        </Container>
                    </div>
                )}
            </Formik>
        );
    }
}

export default withRouter(SignUp);
