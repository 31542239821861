import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import Logo from "../../assets/logo.png";
import Close from "../../assets/close.png";

import { Form, Container } from "./styles";
import api from "../../services/api";
import { setUser, setPopup, getPopup, logout, login, setUserDetails, getUserDetails } from "../../services/auth";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";

class SignIn extends Component {
    constructor() {
        super();
        logout();

        this.state = {
            username: "",
            password: "",
            error: "",
            loading: false,
            showPromo: false,
            showPopup: false,
            prompt: null
        };

    }

    popupHandler() {
        const isPopupHandled = getPopup();
        //console.log(getPopup());
        if(!isPopupHandled) {
            this.setState({showPopup: true});
            setPopup();
        } else {
            this.setState({showPopup: false});
        }
    }
    
    componentDidMount() {
        this.checkInstall();
        this.popupHandler();
        this.autoLogin();
    }
    
    checkInstall() {
        let deferredPrompt;

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            //console.log("prevent");
            deferredPrompt = e;
            this.setState({showPromo: true});
        });

        this.nv.addEventListener('click', (e) => {
            // Show the install prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
              if (choiceResult.outcome === 'accepted') {
                //console.log('User accepted the install prompt');
              } else {
                this.setState({showPromo: false});
              }
            });
        });
    }

    clickClose() {
        //console.log("dismiss");
        this.setState({showPromo: false});
    }

    closePopup() {
        //console.log("dismiss");
        this.setState({showPopup: false});
        setPopup();
    }

    clickInstall() {
        //console.log("dismiss");
        this.state.prompt.prompt();
    }

    autoLogin = async e => {
        if(getUserDetails(false) != null) {
            const username = window.atob(getUserDetails(true));
            const password = window.atob(getUserDetails(false));
            this.setState({loading: true});
            try {
                await api.post("/users/login", { username, password }).then(res=>{
                    //console.log('retorno: ', res.data.user);
                    setUser(JSON.stringify(res.data.user));
                    setUserDetails(username, password);
                    login(res.data.data.token);
                    this.setState({
                        error: "",
                        loading: false,
                    });
                    this.props.history.push("/");
                    
                }).catch(err=>{
                    //console.log('erro retorno: ', err);
                    this.setState({
                        error: "Login ou Senha inválido. Tente novamente.",
                        loading: false,
                    });
                });
            } catch (err) {
                //console.log(err);
                this.setState({
                    error: "Ocorreu um erro ao registrar sua conta.",
                    loading: false,
                });
            }
        }
    }

    handleSignIn = async e => {
        this.setState({loading: true});
        const { username, password } = this.state;
        try {
            await api.post("/users/login", { username, password }).then(res=>{
                //console.log('retorno: ', res.data.user);
                setUser(JSON.stringify(res.data.user));
                setUserDetails(username, password);
                login(res.data.data.token);
                this.setState({
                    error: "",
                    loading: false,
                });
                this.props.history.push("/");
                
            }).catch(err=>{
                //console.log('erro retorno: ', err);
                this.setState({
                    error: "Login ou Senha inválido. Tente novamente.",
                    loading: false,
                });
            });
        } catch (err) {
            //console.log(err);
            this.setState({
                error: "Ocorreu um erro ao registrar sua conta.",
                loading: false,
            });
        }
    };

    handleComplaintAnonymous = e => {
        this.props.history.push("/ComplaintAnonymous");
    };

    render() {
        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;
        return (
            <div>
                {this.state.loading && <div className="sweet-loading">
                    <PacmanLoader
                        css={override}
                        size={50}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>}
                <div className="install" style={{display: this.state.showPromo !== false ? "block" : "none"}}>
                    <img src={Close} onClick={() => this.clickClose()} alt="close" />
                    <h6>INSTALE NOSSO APLICATIVO<br />E TENHA ACESSO A MAIS NOVIDADES!</h6>
                    <button ref={elem => this.nv = elem}>INSTALAR</button>
                </div>
                <div className="overlay" style={{display: this.state.showPopup !== false ? "block" : "none"}}>
                    <div className="popup">
                        <img src={Close} className="btnClose" onClick={() => this.closePopup()} alt="close" />
                        <h4>Circo de Todo Mundo</h4>
                        <hr />
                        <h5>O projeto Semeando Uma Cultura de Direitos , tem como objetivo  contribuir para a prevenção e a 
                            erradicação do trabalho infantil, em suas diferentes formas, 
                            através de um Aplicativo - Plataforma Hibrida, uma ferramenta tecnológica que 
                            possibilita crianças e adolescentes aprender brincando.</h5>
                    </div>
                </div>
                <Container>
                    <Form onSubmit={this.handleSignIn} className="form">
                        <img src={Logo} className="logo" alt="Circo de Todo Mundo logo" />
                        {this.state.error && <p>{this.state.error}</p>}
                        <div className="form-group row w-100">
                            <div className="col-sm-8 offset-sm-2 text-center">
                                <input
                                    type="text"
                                    placeholder="Nome de Usuário"
                                    className="username"
                                    onChange={e => this.setState({ username: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className="form-group row w-100">
                            <div className="col-sm-8 offset-sm-2 text-center">
                                <input
                                    type="password"
                                    placeholder="Senha"
                                    className="password"
                                    onChange={e => this.setState({ password: e.target.value })}
                                />
                            </div>
                        </div>
                        <Link to="/RecoverPassword">Esqueci minha senha</Link>
                        <div className="form-group row w-100">
                            <div className="col-sm-8 offset-sm-2 text-center">
                                <button type="button" className="btn-login" onClick={this.handleSignIn} alt="Entrar">Entrar</button>
                            </div>
                        </div>
                        <Link to="/SignUp">Fazer Cadastro</Link>
                        <div className="form-group row w-100">
                            <div className="col-sm-8 offset-sm-2 text-center">
                                <button type="button" className="btn-denuncia-anonima" onClick={this.handleComplaintAnonymous} alt="Denúncia Anônima">Denúncia Anônima</button>
                            </div>
                        </div>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default withRouter(SignIn);
