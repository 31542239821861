import styled from "styled-components";
import backgroundTerms from "../../assets/background_terms.jpg";

export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundTerms});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100px;
    padding-right: 2%;
    padding-left: 2%;
    font-family: 'Roboto Slab', serif;
    text-align: center;
    height: 100vh;
    color: white;

    .about {
        background-color: rgba(0,0,0,0.8);
        padding: 2%;
        border-radius: 10px;
    }

    hr {
        background-color: rgba(255,255,255,0.5);
    }

    .terms {
        margin: 0 15px;
        padding: 15px;
        color: white;
        text-align: justify;
        text-justify: inter-word;
    }

    .img-header img {
        margin-top: 20px;
        max-width: 50%;
    }

    .link {
        margin: 15px 15px 0 15px;
        padding: 15px;
    }
`;