import styled from "styled-components";
import backgroundSignup from "../../assets/background_signup.jpg";
import backgroundInput1 from "../../assets/background_input_1.png";
import backgroundInput2 from "../../assets/background_input_2.png";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-image: url(${backgroundSignup});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

export const FormStile = styled.form`
    width: 100%;
    background: transparent;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .form-check-input {
        margin-left: 2%;
    }

    .form-check {
        background-color: rgba(240, 240, 240, 0.95);
        padding: 3%;
        border-radius: 3px;
        width: 100%;
        margin-top: 5%;
    }

    .title {
        margin-top: 60px;
        background-color: rgba(33, 78, 120, 1);
        border-radius: 10px;
        padding: 3%;
        color: #fff;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7));
    }

    p {
        color: #ff3333;
        margin-top: 15%;
        margin-bottom: 2%;
        padding: 10px;
        width: 100%;
        text-align: center;
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    .invalid-feedback {
        color: #a30a0a;
        font-size: 1.2rem;
        font-weight: bold;
        background-color: rgba(230, 230, 230, 0.8);
        border-radius: 15px;
    }

    .password {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
        color: #fff !important;
        ::placeholder {
            color: #fff !important;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #fff !important;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: #fff !important;
        }
    }

    .birth_date {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
        border: 0px;
        color: #fff !important;
        height: 45px;
        padding-left: 5%;
        padding-right: 10%;
        ::placeholder {
            color: #fff !important;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #fff !important;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: #fff !important;
        }
    }

    .react-datepicker-input, .react-datepicker-input:hover, .react-datepicker-input:active, .react-datepicker-input:focus, .react-datepicker-input.is-open {
        background: transparent;
        border: 0px;
        color: white;
        font-size: 16px;
        text-align: center;
        width: 100%;
        position: relative;
    }
    
    .react-datepicker-input.has-value input, .react-datepicker-input input {
        color: white !important;
        font-size: 21px;
        font-weight: 400;
        text-align: center;
        width: 100%;
        padding: 0;
        padding-top: 1%;
    }

    .icon-rc-datepicker {
        transition: all 0.2s;
        position: absolute;
        top: 0%;
        margin-top: -65%;
        font-size: 20px;
        color: white;
    }

    .icon-rc-datepicker:hover {
        transition: all 0.2s;
        transform: scale(1.2, 1.2);
    }

    input[type="text"], input[type="date"], input[type="password"] {
        flex: 1;
        height: 46px;
        margin-bottom: 15px;
        padding: 10px 10px;
        color: yellow;
        font-size: 1.5em;
        width: 90%;
        border: 0;
        &::placeholder {
            color: #999;
        }
        text-align: center;
        ::placeholder {
            color: yellow;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: yellow;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: yellow;
        }
        outline: none;
    }

    .boy, .girl {
        max-width: 50px;
    }

    .form-check-label {
        color: #000;
        font-size: 90%;
        margin-left: 10%;
    }

    .background1 {
        background:url(${backgroundInput1}) no-repeat right top;
        background-size: 100% 100%;
    }

    .background2 {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
    }

    .username {
        margin-top: 5%;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: yellow;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: yellow;
    }

    .label_birth_date {
        display: inline-block;
        width: 90%;
        padding: 10px;
        margin-bottom: -10px;
    }

    button {
        color: #fff;
        font-size: 16px;
        background: #fc6963;
        height: 56px;
        border: 0;
        border-radius: 5px;
        width: 100%;
    }
    hr {
        margin: 20px 0;
        border: none;
        border-bottom: 1px solid #cdcdcd;
        width: 100%;
    }
    a {
        font-size: 16;
        font-weight: bold;
        color: #999;
        text-decoration: none;
    }
    .btn {
        width: 70%;
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.7));
    }
`;
