import React from 'react';
import ReactDOM from 'react-dom';

const mapStyles = {
    map: {
        position: 'relative',
        width: '90%',
        height: '200px',
        marginTop: '20px',
        left: '5%'
    }
};

export class CurrentLocation extends React.Component {

    constructor(props) {
        super(props);

        //const { lat, lng } = this.props.currentLocation;

        this.state = {
            currentLocation: {
                lat: this.props.currentLocation.lat,
                lng: this.props.currentLocation.lng
            },
            updateCurrentLocation: this.props.updateCurrentLocation
        };
        //console.log('coordenadas no construtor: ', this.props.currentLocation)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.google !== this.props.google) {
            this.loadMap();
        }
        if (prevState.currentLocation.lat !== this.props.currentLocation.lat || prevState.currentLocation.lng !== this.props.currentLocation.lng) {
            if (this.props.currentLocation.lng !== '') {
                //console.log('currente: ' , prevState.currentLocation, ' - ' , 'agora: ' , this.props.currentLocation);
                prevState.currentLocation = this.props.currentLocation;
                this.recenterMap();
            }
        }
    }

    recenterMap() {
        //console.log('recenterMap');
        const map = this.map;
        const current = this.props.currentLocation;

        const google = this.props.google;
        const maps = google.maps;

        if (map) {
            let center = new maps.LatLng(current.lat, current.lng);
            map.panTo(center);
        }
    }

    componentDidMount() {
        //console.log('componentDidMount');
        if (this.props.centerAroundCurrentLocation) {
            if (navigator && navigator.geolocation && this.state.updateCurrentLocation) {
                navigator.geolocation.getCurrentPosition(pos => {
                    const coords = pos.coords;
                    this.setState({
                        currentLocation: {
                            lat: coords.latitude,
                            lng: coords.longitude
                        }
                    });
                    this.loadMap();
                });
            } else {
                this.loadMap();
            }
        } else {
            this.loadMap();
        }
    }

    loadMap() {
        //console.log('loadMap');
        if (this.props && this.props.google) {
            // checks if google is available
            const { google } = this.props;
            const maps = google.maps;

            const mapRef = this.refs.map;

            // reference to the actual DOM element
            const node = ReactDOM.findDOMNode(mapRef);

            let { zoom } = this.props;
            const { lat, lng } = this.state.currentLocation;
            const center = new maps.LatLng(lat, lng);
            //console.log('lat: ' + lat + ' - lng: ' + lng);
            const mapConfig = Object.assign(
                {},
                {
                    center: center,
                    zoom: zoom,
                    disableDoubleClickZoom: true
                }
            );
            let onclick = this.props.mapClicked;

            // maps.Map() is constructor that instantiates the map
            this.map = new maps.Map(node, mapConfig);

            if (this.props.onClickMap) {
                maps.event.addListener(this.map, 'click', function(args) {
                    //console.log('latlng', args.latLng);
                    //console.log('lat: ' , lat);
                    onclick(args.latLng.lat(), args.latLng.lng());
                });
            }

        }
    }

    renderChildren() {
        const { children } = this.props;

        if (!children) return;

        return React.Children.map(children, c => {
            if (!c) return;
            return React.cloneElement(c, {
                map: this.map,
                google: this.props.google,
                mapCenter: this.props.currentLocation
            });
        });
    }

    render() {
        const style = Object.assign({}, mapStyles.map);
        return (
            <div>
                <div style={style} ref="map">
                    Loading map...
                </div>
                {this.renderChildren()}
            </div>
        );
    }

}
export default CurrentLocation;

CurrentLocation.defaultProps = {
    zoom: 14,
    currentLocation: {
        lat: -1.2884,
        lng: 36.8233
    },
    centerAroundCurrentLocation: false,
    visible: true,
    updateCurrentLocation: true,
};
