import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";
import Logo from "../../assets/logo.png";
import { Form, Container } from "./styles";
import api from "../../services/api";
import { ToastContainer, toast } from 'react-toastify';

class RecoverPassword extends Component {

    state = {
        email: "",
        error: "",
        loading: false,
    };

    handleRecoverPassword = async e => {
        this.setState({loading: true});
        const Msg = () => (
          <div>
            Uma nova senha foi enviada para seu e-mail.<hr />
            <Link to="/SignIn" className="link-login-toast">Ir para Login</Link>
          </div>
      );
        const { email } = this.state;
        try {
            //console.log('email: ', email);
            await api.post("/users/recoverPassword", { email }).then(res=>{
                //console.log('retorno: ', res.data);
                this.setState({
                    error: "",
                    loading: false,
                });
                toast(<Msg />);
                //this.props.history.push("/");
            }).catch(err=>{
                //console.log('erro retorno: ', err.response.data.message);
                let message = "E-mail inválido. Tente novamente.";
                if (err.response && err.response.data && err.response.data.message !== undefined && err.response.data.message !== '') {
                    message = err.response.data.message;
                }
                this.setState({
                    error: message,
                    loading: false,
                });
            });
        } catch (err) {
            //console.log(err);
            this.setState({
                error: "Ocorreu um erro ao enviar os dados.",
                loading: false,
            });
        }
    };

    render() {
        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;
        return (
            <div>
                {this.state.loading && <div className="sweet-loading">
                    <PacmanLoader
                        css={override}
                        size={50}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>}
                <ToastContainer
                    hideProgressBar={true}
                    autoClose={false}
                    closeButton={false}
                    className='toast-container'
                    toastClassName="custom-toast"
                    progressClassName={css({
                        height: "2px"
                    })}
                />
                <Container>
                    <Form onSubmit={this.handleRecoverPassword} className="form">
                        <img src={Logo} className="logo" alt="Circo de Todo Mundo logo" />
                        {this.state.error && <p>{this.state.error}</p>}
                        <div className="form-group row w-100">
                            <div className="col-sm-8 offset-sm-2 text-center">
                                <input
                                    type="text"
                                    placeholder="E-mail"
                                    className="email"
                                    onChange={e => this.setState({ email: e.target.value })}
                                />
                            </div>
                        </div>

                        <div className="form-group row w-100">
                            <div className="col-sm-8 offset-sm-2 text-center">
                                <button type="button" className="btn-send" onClick={this.handleRecoverPassword} alt="Entrar">Recuperar Senha</button><br/>
                                <Link to="/"><button type="button" className="back">Voltar</button></Link>
                            </div>
                        </div>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default withRouter(RecoverPassword);
