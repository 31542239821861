import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Container } from "./styles";
import SimpleMenu from "../../components/Menu";
// import PreviousTerms from "../../assets/previous_terms.png";
import TermsImg from "../../assets/terms.png";
import { getUser } from "../../services/auth";

class About extends Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(getUser());
        //console.log('usuario: ', user);
        this.state = {
            user: user
        }
    }

    render() {
        return (
            <div>
                {this.state.user && <SimpleMenu />}
                
                <Container>
                    {!this.state.user && <div className="w-100 text-center img-header">
                            <img src={TermsImg} alt="Termos de Uso" />
                    </div>}
                    <div className="about">
                    <h4>Sobre o Projeto</h4>
                    <hr />
                    <h5>O projeto Semeando Uma Cultura de Direitos , tem como objetivo  contribuir para a prevenção e a 
                        erradicação do trabalho infantil, em suas diferentes formas, 
                        através de um Aplicativo - Plataforma Hibrida, uma ferramenta tecnológica que 
                        possibilita crianças e adolescentes aprender brincando.<br /> O trabalho infantil é uma violência com a criança...</h5>
                        <h5>"A violência não é força, mas fraqueza, nem nunca poderá ser criadora de coisa alguma, apenas destruidora."
                        Benedetto Croce</h5>
                    </div>
                        
                </Container>
            </div>
        );
    }
}

export default withRouter(About);
