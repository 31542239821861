import styled from "styled-components";
import backgroundComplaint from "../../assets/background_complaint.jpg";
import backgroundBtnDenunciaAnonima from "../../assets/btn_denuncia_anonima.png";
import backgroundInput3 from "../../assets/background_input_3.png";

export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundComplaint});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 60px;

    .map-title {
        background-color: rgba(240, 240, 240, 0.8);
        padding: 3%;
        margin-top: 6%;
        border-radius: 3px;
    }

    .btn-denuncia-anonima {
        width: 80%;
        background:url(${backgroundBtnDenunciaAnonima}) no-repeat right top;
        background-size: 100% 100%;
        color: #fff;
        outline: none;
        padding: 1rem .8rem 1.3rem .8rem;
        margin-top: 5%;
        font-size: 1.5rem;
        background-color: transparent;
        border: 0;
    }

    input[type="button"]:focus,
    input[type="button"]:active {
        -moz-outline-style: none;
        outline:none;
        outline:0;
    }

    .occurrence {
        max-width: 60%;
    }

    .occurrence-active {
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    .invalid-feedback {
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    .preview {
        max-width: 80%;
    }

    .btn-capture-image {
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
        padding: 5px 0;
    }

    label {
        margin: 0;
    }

    .link-phone {
        margin-top: 20px;
    }

    a[href^="tel:"] {
        color: #2CBAE0;
        text-decoration: none;
        background-color: rgb(255,255,255,0.95);
        border-radius: 5px;
        padding: 3%;
        font-size: 21px;
        text-transform: uppercase;
    }
`;

export const FormStile = styled.form`
    width: 100%;
    background: transparent;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        color: #ff3333;
        margin-bottom: 15px;
        padding: 10px;
        width: 100%;
        text-align: center;
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    input[type="text"], input[type="date"], input[type="password"] {
        flex: 1;
        height: 46px;
        padding: 10px 0;
        margin-top: 10px;
        color: #214e78;
        font-size: 1.5em;
        width: 100%;
        border: 0;
        &::placeholder {
            color: #999;
        }
        text-align: center;
        ::placeholder {
            color: #214e78;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #214e78;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: #214e78;
        }
        outline: none;
    }

    .form-check-input {
        margin-left: 1%;
    }

    .form-check {
        background-color: rgba(240, 240, 240, 0.9);
        padding: 3%;
        border-radius: 3px;
        width: 100%;
    }

    .form-check-label {
        color: #000;
        font-size: 90%;
        margin-left: 10%;
    }

    .background3 {
        background:url(${backgroundInput3}) no-repeat right top;
        background-size: 100% 100%;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #214e78;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #214e78;
    }

    .label_birth_date {
        display: inline-block;
        width: 90%;
        padding: 10px;
        margin-bottom: -10px;
    }

    button {
        color: #fff;
        font-size: 16px;
        background: #fc6963;
        height: 56px;
        border: 0;
        border-radius: 5px;
        width: 100%;
    }
    hr {
        margin: 20px 0;
        border: none;
        border-bottom: 1px solid #cdcdcd;
        width: 100%;
    }
    a {
        font-size: 16;
        font-weight: bold;
        color: #999;
        text-decoration: none;
    }
    .btn {
        width: 70%;
    }
`;
