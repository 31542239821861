import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container } from "./styles";
import SimpleMenu from "../../components/Menu";
import PreviousTerms from "../../assets/previous_terms.png";
// import TermsImg from "../../assets/terms.png";
import { getUser } from "../../services/auth";

class Terms extends Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(getUser());
        //console.log('usuario: ', user);
        this.state = {
            user: user
        }
    }

    render() {
        return (
            <div>
                {this.state.user && <SimpleMenu />}
                <Container>
                    {/* {!this.state.user && <div className="w-100 text-center img-header">
                            <img src={TermsImg} alt="Termos de Uso" />
                    </div>} */}
                    <div className="terms">
                        <h4><u>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</u></h4>
                        <hr />
                        <p>O <b>CENTRO RECREAÇÃO DE ATENDIMENTO E DEFESA DA CRIANÇA E DO ADOLESCENTE</b> (“CIRCO DE TODO MUNDO”) pessoa jurídica de direito privado inscrita no CNPJ nº 71.089.809/0001-60, com sede na Rua Cícero Rabelo de Vasconcelos, nº 110, Conjunto Olímpia Bueno Franco - CEP: 32671-820 em Betim – Minas Gerais é o criador e responsável pela manutenção de aplicativo do Projeto Semeando uma Cultura de Direitos, no qual são disponibilizados jogos, desafios e canal de comunicação para denúncias, voltados para divulgação, conscientização e educação acerca da erradicação do trabalho infantil, visando a proteção e o melhor interesse de crianças e adolescentes Usuários do aplicativo.</p>
                        <p>Por meio do presente documento, o CIRCO DE TODO MUNDO apresenta os Termos e Condições de Uso e Política de Privacidade da plataforma interativa, explicando como funciona o aplicativo e como serão tratados os dados dos Usuários.</p>
                        <p><b>1. <u>ACEITE DO USUÁRIO</u></b></p>
                        <p><b>1.1. Para acessar todos os conteúdos do aplicativo, usufruir de seus benefícios e realizar denúncias, o Usuário deverá declarar que conhece e concorda com todas as regras do presente documento. Caso o Usuário não concorde, não será possível que ele utilize todas as funcionalidades do aplicativo.</b></p>
                        <p>1.1.1. O aceite ao presente documento se dará pelo <span style={{backgroundColor:"grey"}}>clique no botão “aceito” ao final do documento</span>. O acesso do Usuário ao aplicativo, com login e senha, e o uso continuado do aplicativo, também significam que o Usuário concorda com as regras do presente documento.</p>
                        <p>1.1.2. Para comprovar o aceite, o uso do aplicativo e a manifestação de vontade do Usuário nesse sentido, o CIRCO DE TODO MUNDO poderá armazenar registro de logs do Usuário.</p>
                        <p><b>1.2. O aceite significa que o Usuário concorda com o presente documento e concorda com o tratamento de seus dados, nos termos do item 4 - Política de Privacidade.</b></p>
                        <p><b>1.3. </b>Qualquer pessoa física, de qualquer idade, que se cadastre no aplicativo, poderá acessar os seus conteúdos.</p>
                        <p><b>2. <u>CADASTRO E ACESSO</u></b></p>
                        <p><b>2.1. </b>O Usuário poderá se cadastrar diretamente no aplicativo, de forma simples, criando um login e senha para navegação e acesso aos jogos, desafios e canal de denúncias.</p>
                        <p><b>2.2. </b>O Usuário não pode informar seu login e senha para outras pessoal. O Usuário é o único responsável pela guarda e segurança do login e senha que criou, e fica ciente de que essas informações não deverão ser divulgadas, compartilhadas ou repassadas de qualquer forma a outras pessoas.</p>
                        <p>2.2.1. Caso o Usuário descumpra o item 2.2, o CIRCO DE TODO MUNDO não se responsabilizará por nenhum prejuízo decorrente do uso / acesso indevido de outras pessoas ao cadastro ou dados do Usuário.</p>
                        <p><b>2.3. </b>O Usuário deverá manter seus dados cadastrais atualizados, sempre que for convidado a esta revalidação cadastral pelo aplicativo e deve sempre fornecer informações verdadeiras ao CIRCO DE TODO MUNDO.</p>
                        <p>2.3.1. Em caso de cadastros com informações falsas, o CIRCO DE TODO MUNDO poderá excluir o cadastro do Usuário.</p>
                        <p><b>3. <u>CONTEÚDO DO APLICATIVO</u></b></p>
                        <p><b>3.1. </b>Por meio do aplicativo, o Usuário poderá denunciar situações de abuso de crianças e adolescentes, por exemplo: trabalho infantil, privação de liberdade, ausência da escola, obrigações de cuidar do bebê, cozinhar, cuidar da casa. Para denunciar, o Usuário deverá informar a situação e o local da ocorrência, clicar no botão “enviar” e enviar a mensagem por meio de formulário de contato.</p>
                        <p>3.1.1. A denúncia pode ser feita de forma anônima, se o Usuário selecionar essa opção no momento do envio.</p>
                        <p>3.1.2. Ao enviar a denúncia, o Usuário poderá autorizar que o CIRCO DE TODO MUNDO entre em contato com o Usuário para obter mais informações sobre a denúncia.</p>
                        <p>3.1.3. Ao realizar a denúncia, o Usuário poderá tirar ou selecionar uma foto, e enviá-la ao CIRCO DE TODO MUNDO.</p>
                        <p>3.1.4. As informações e imagens fornecidas pelo Usuário na denúncia serão utilizadas pelo CIRCO DE TODO MUNDO com a finalidade de proteção dos interesses de crianças e adolescentes. Assim, o CIRCO DE TODO MUNDO poderá usar as informações para promover a apuração da denúncia, inclusive com encaminhando o caso às autoridades competentes. O CIRCO DE TODO MUNDO não se responsabiliza pelo resultado das investigações realizadas pelas autoridades.</p>
                        <p><b>3.2. </b>Por meio do aplicativo, o Usuário terá aceso a uma Biblioteca com publicações do CIRCO DE TODO MUNDO.</p>
                        <p>3.2.1. O Usuário poderá utilizar o conteúdo da Biblioteca para consulta e leitura, por meio de leitor de arquivo pdf externo ao aplicativo. O conteúdo da Biblioteca estará disponível apenas para visualização, não sendo possível o seu download.</p>
                        <p>3.2.2. Caso faça alguma citação dos materiais da Biblioteca, o Usuário se compromete a: (i) conferir aos autores e ao CIRCO DE TODO MUNDO os créditos de autoria sobre os materiais que acessar no website, (ii) não utilizar o material de qualquer forma que prejudique a imagem dos autores da obra, (iii) respeitar todos os demais direitos morais de autor sobre o material em questão.</p>
                        <p><b>3.3. </b>O Usuário terá acesso a jogos e desafios por meio do aplicativo.</p>
                        <p>3.3.1. Os conteúdos direcionados a crianças com idade entre 6 e 9 anos incluem Quiz (tela mostrando imagens de situações cotidianas com opção para a criança marcar se trata-se de trabalho ou brincadeira), Spinner (o usuário tem chances de rodar o spinner, ao final ganha uma pontuação que fica salva no seu perfil), Chuva (o Usuário apagará instrumentos de trabalho infantil e ao final, o jogo revelará mensagens de estímulo à denúncia de situações de risco ou de positividade voltada à pratica de brincadeiras).</p>
                        <p>3.3.2. Os conteúdos direcionados a jovens com idade entre 10 e 17 anos incluem Bubble Hit com objetos característicos do trabalho infantil, Tangram (o objetivo do jogo é usar todas as sete peças, sem sobrepor uma na outra, para formar a figura mostrada).</p>
                        <p>3.3.3. O Usuário concorda que o CIRCO DE TODO MUNDO poderá salvar a pontuação do Usuário em seu perfil e exibir seu o nome e pontuação em um ranking.</p>
                        <p><b>3.4. </b>O Usuário cadastrado e logado terá acesso ao Chat, uma plataforma de comunicação com outros Usuários, com possibilidade de conversas realizadas em grupo.</p>
                        <p>3.4.1. O Usuário fica ciente de que as opiniões contidas em comentários feitos no Chat não se confundem com a opinião institucional do CIRCO DE TODO MUNDO, e que o CIRCO DE TODO MUNDO não se responsabiliza pelo conteúdo de tais comentários.</p>
                        <p>3.4.2. O Usuário é o único responsável pelas mensagens que postar no Chat. O Usuário se compromete a não enviar mensagens com conteúdo que (i) incite violência, racismo ou discriminação de qualquer tipo, (ii) viole a legislação ou direitos de outras pessoas, ou (iii) que tenha conteúdo de spam, propaganda, vírus ou qualquer tipo de conteúdo ofensivo, lesivo, calunioso, injurioso ou difamatório.</p>
                        <p>3.4.3. Em caso de violação das regras para uso do Chat, o CIRCO DE TODO MUNDO poderá retirar o conteúdo do aplicativo e excluir o cadastro do Usuário.</p>
                        <p>3.4.4. O Usuário fica ciente de que as mensagens do Chat podem ser acessadas pelo CIRCO DE TODO MUNDO, para fins de monitoramento do cumprimento dos Termos de Uso e visando a proteção dos Usuários.</p>
                        <p><b>4. <u>POLÍTICA DE PRIVACIDADE</u></b></p>
                        <p><b>4.1. </b>O CIRCO DE TODO MUNDO poderá realizar tratamento de dados pessoais fornecidos pelo Usuário no aplicativo, no momento de cadastro ou navegação, tais como nome, data de nascimento, imagem, e-mail e local da denúncia. Além disso, o CIRCO DE TODO MUNDO poderá realizar tratamento de dados decorrentes do histórico de navegação do Usuário no aplicativo, incluindo informações sobre a pontuação e o ranking nos jogos disponibilizados na plataforma interativa.</p>
                        <p>4.1.1. O CIRCO DE TODO MUNDO coletará apenas os dados do Usuário que forem necessários para o seu acesso ao aplicativo.</p>
                        <p>4.1.2. Para tratamento de dados, o CIRCO DE TODO MUNDO poderá utilizar tecnologias como <span style={{backgroundColor:"grey"}}>cookies e web beacons.</span></p>
                        <p><b>4.2. O Usuário não é obrigado a permitir a coleta de seus dados. No entanto, caso escolha não compartilhar suas informações, o Usuário fica ciente de que poderá não ter acesso a algumas funcionalidades ou conteúdo do aplicativo.</b></p>
                        <p><b>4.3. </b>O CIRCO DE TODO MUNDO poderá tratar, armazenar ou utilizar os dados indicados no item 4.1 para a finalidade de proteção do Usuário, incluindo denúncias relativas ao trabalho infantil, compartilhamento com as autoridades competentes, uso do aplicativo pelo Usuário, inclusive sua identificação para participação em ranking de pontuação nos jogos, envio de comunicações ao Usuário por e-mail, prestação de contas, melhorar o funcionamento e a segurança do aplicativo, realizar avaliações estatísticas, cumprir ordem legal, judicial, administrativa ou exercer direitos em juízo, responder a perguntas e solicitações do Usuário, avaliar eventuais problemas e apurar condutas que violem o presente documento.</p>
                        <p>4.3.1. O Usuário poderá, a qualquer tempo, solicitar que seja cessado o recebimento de e-mails e comunicações enviadas pelo CIRCO DE TODO MUNDO, seguindo as instruções indicadas no corpo do e-mail recebido ou realizando a solicitação pelos seguintes canais de contato: <p>(31) 3591-2903 ou email comunicacao@circodetodomundo.org.br</p></p>
                        <p><b>4.4. </b>Os dados de Usuário coletados pelo CIRCO DE TODO MUNDO serão armazenados em servidor seguro, até o fim do tratamento de dados ou a solicitação de sua exclusão pelo Usuário.</p>
                        <p>4.4.1. O CIRCO DE TODO MUNDO adotará medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito, mesmo após o término do tratamento de dados, conforme padrões mínimos exigidos pela legislação aplicável.</p>
                        <p><b>4.5. </b>O Usuário poderá exercer os seguintes direitos relativos ao tratamento de seus dados, nos termos da Lei 13.709/2018:</p>
                        <p>a) consulta facilitada e gratuita sobre a forma e a duração do tratamento, bem como sobre a integralidade de seus dados pessoais;</p>
                        <p>b) obter a qualquer momento, mediante requisição:<br />
                        I - confirmação da existência de tratamento;<br />
                        II - acesso aos dados;<br />
                        III - correção de dados incompletos, inexatos ou desatualizados;<br />
                        IV - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709/2018;<br />
                        V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;<br />
                        VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709/2018;<br />
                        VII - informação das entidades públicas e privadas com as quais o CIRCO DE TODO MUNDO realizou uso compartilhado de dados; <br />
                        VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;<br />
                        IX - revogação do consentimento, mediante manifestação expressa do Usuário.
                        </p>
                        <p>c) solicitar cópia eletrônica integral de seus dados pessoais, observados os segredos comercial e industrial, em formato que permita a sua utilização subsequente, inclusive em outras operações de tratamento;</p>
                        <p>d) solicitar a revisão de eventuais decisões tomadas pelo CIRCO DE TODO MUNDO unicamente com base em tratamento automatizado de dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definir o seu perfil pessoal, profissional, de consumo e de crédito ou os aspectos de sua personalidade;</p>
                        <p>e) peticionar perante o CIRCO DE TODO MUNDO em relação aos seus dados.</p>
                        <p><b>4.6. O CIRCO DE TODO MUNDO disponibiliza os seguintes canais de comunicação para contato pelo Usuário, em caso de necessidade de esclarecimentos, reclamações, comunicações ou para exercer os direitos indicados no item 4.5: <p>(31) 3591-2903 ou email comunicacao@circodetodomundo.org.br</p></b></p>
                        <p><b>5. <u>VIOLAÇÕES DOS TERMOS DE USO</u></b></p>
                        <p><b>5.1.</b> Ao realizar seu cadastro, o Usuário se compromete a respeitar e cumprir o presente documento. Em caso de descumprimento das suas disposições, o CIRCO DE TODO MUNDO se reserva o direito de excluir imediatamente o cadastro do Usuário, sua pontuação nos jogos e suas mensagens, sem prejuízo da apuração de perdas e danos e da responsabilização do Usuário nos termos da legislação civil e criminal.</p>
                        <p><b>6. <u>DISPOSIÇÕES GERAIS</u></b></p>
                        <p><b>6.1. </b>Ao realizar seu cadastro, o Usuário recebe autorização do CIRCO DE TODO MUNDO para uso gratuito do software relativo ao aplicativo. O Usuário se compromete a não promover ou tentar realizar qualquer tipo de engenharia reversa, violação, abertura ou quebra de código fonte do aplicativo / sistema disponibilizado pelo CONTRATADO, sob pena de exclusão imediata do cadastro do Usuário, sem prejuízo da apuração de perdas e danos.</p>
                        <p><b>6.2. </b>O Usuário fica ciente de que o CIRCO DE TODO MUNDO poderá suspender ou encerrar o aplicativo a qualquer momento, sem aviso prévio e sem ônus para o CIRCO DE TODO MUNDO.</p>
                        <p><b>6.3. </b>O Usuário fica ciente de que o aplicativo não é imune a eventuais invasões por terceiros de má-fé ou falhas, e poderão apresentar instabilidade ocasional, característica dos serviços de tecnologia.</p>
                        <p>6.3.1. Em caso de falhas / instabilidade, o CIRCO DE TODO MUNDO se compromete a promover os esforços necessários para restabelecimento pleno funcionamento do aplicativo em tempo razoável.</p>
                        <p>6.3.2. O Usuário fica ciente de que incidentes cibernéticos e invasões do sistema por terceiros de má-fé são eventos alheiros à vontade e responsabilidade do CIRCO DE TODO MUNDO. Nesses casos, o CIRCO DE TODO MUNDO se reserva o direito de tomar todas as medidas cabíveis para responsabilização dos infratores, nas esferas civil e criminal.</p>
                        <p><b>6.4. </b>O presente documento entra em vigor no dia <span style={{backgroundColor:"grey"}}>13/07/2020</span>, gerando efeitos para o Usuário na data do aceite digital deste no aplicativo.</p>
                        <p><b>6.5. </b>O CIRCO DE TODO MUNDO poderá alterar o presente documento a qualquer tempo, <span style={{backgroundColor:"grey"}}>comunicando o Usuário no momento do login.</span></p>
                        <p><b>6.6. </b>Fica eleito o foro da comarca de Betim / MG, com exclusão de qualquer outro, para dirimir qualquer dúvida relativa ao presente documento, que será regido pela legislação brasileira.</p>
                        <hr />
                        {/* <input type="checkbox" id="check" name="check" /><label for="check">Declaro que li e aceito os termos de uso.</label> */}
                        {!this.state.user && <div className="w-100 text-center">
                            <Link to="/SignUp" className="text-success link">
                                <img src={PreviousTerms} alt="Voltar" />
                            </Link>
                        </div>}
                        {!this.state.user && <br />}
                    </div>

                </Container>
            </div>
        );
    }
}

export default withRouter(Terms);
