import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container } from "./styles";
import SimpleMenu from "../../components/Menu";
import PreviousTerms from "../../assets/previous_terms.png";
import CrasImg from "../../assets/cras.png";
import { getUser } from "../../services/auth";

class CRAS extends Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(getUser());
        //console.log('usuario: ', user);
        this.state = {
            user: user
        }
    }

    render() {
        return (
            <div>
                {this.state.user && <SimpleMenu />}
                <Container>
                    {this.state.user && <div className="w-100 text-center img-header">
                            <img src={CrasImg} alt="Termos de Uso" />
                    </div>}
                    <div className="cras">
                    <p><b>DISQUE 100 PARA DENUNCIAR</b></p>
                    <p>
                    <b>Conselho Municipal dos Direitos da Criança e do Adolescente - Betim</b><br />
                    (31) 3594-1222
                    </p>

                    <p>
                    <b>Promotorias da infância e juventude de Betim</b><br />
                    (31) 3596-8226
                    </p>

                    <p>
                    <b>REGIONAL ALTEROSAS</b><br />
                    CRAS ALTEROSAS I - 3591-5645<br />
                    CRAS ALTEROSAS II - 3592-3823
                    </p>

                    <p>
                    <b>REGIONAL CENTRO</b><br />
                    CRAS Bandeirinhas - 3596-8198<br />
                    CRAS São João - 3593-1392
                    </p>

                    <p>
                    <b>REGIONAL CITROLÂNDIA</b><br />
                    CRAS Alto Boa Vista - 3596-7749<br />
                    CRAS Citrolândia - 3596-1896
                    </p>

                    <p>
                    <b>REGIONAL ICAIVERA</b><br />
                    CRAS Icaivera - 3531-1651
                    </p>

                    <p>
                    <b>REGIONAL IMBIRUÇU</b><br />
                    CRAS Imbiruçu - 3597-6423<br />
                    CRAS Jardim Perla - 3592-7842
                    </p>

                    <p>
                    <b>REGIONAL NORTE</b><br />
                    CRAS Norte - 3595-7793
                    </p>

                    <p>
                    <b>REGIONAL PETROVALE</b><br />
                    CRAS Petrovale - 3594-2420
                    </p>

                    <p>
                    <b>REGIONAL PTB</b><br />
                    CRAS Cruzeiro do PTB - 3593-4392<br />
                    CRAS Guanabara - 3593-5010
                    </p>

                    <p>
                    <b>REGIONAL TERESÓPOLIS</b><br />
                    CRAS Teresópolis - 3591-5604<br />
                    CRAS Vila Recreio - 3511-1102
                    </p>

                    <p>
                    <b>REGIONAL TERESÓPOLIS</b><br />
                    CRAS Vianópolis - 3596-3046
                    </p>

                    <p>
                    <b>NOVA LIMA</b><br />
                    CRAS Cruzeiro - (31)3542-5684<br />
                    CRAS Nordeste - (31)3542-3020<br />
                    CRAS Noroeste - (31)3581-3067<br />
                    CRAS CENTRO - (31) 3547-4790<br />
                    CREAS - (31)3541-4383<br />
                    CMDCA-Nova Lima - (31) 3547-8499<br />
                    CONSELHO TUTELAR SEDE - (31) 3581-8504<br />
                    CONSELHO TUTELAR NOROESTE - (31) 3542-6012
                    </p>
                    </div>
                    {!this.state.user && <div className="w-100 text-center">
                        <Link to="/SignUp" className="text-success link">
                            <img src={PreviousTerms} alt="Voltar" />
                        </Link>
                    </div>}
                    {!this.state.user && <br />}
                </Container>
            </div>
        );
    }
}

export default withRouter(CRAS);
