import styled from "styled-components";

export const Container = styled.div`
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    padding-top: 60px;
    max-width: 583px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .doc_foot {
        background-color: #fff;
        border-radius: 5px;
        width: 60%;
        filter: drop-shadow(0px 8px 10px rgba(32, 32, 32, 0.4));
        position: absolute;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .doc_foot * {
        display: inline-block;
        margin-top: 12px;
    }

    .doc_foot p {
        margin-left: 13%;
        margin-right: 13%;
    }

    .doc_foot button {
        background: none;
        border: 0px;
    }

    .hidden {
        display: none;
    }

    .show {
        display: block;
    }

    .contain {
        background-color: #fff;
        width: 95%;
        height: 87vh;
        border-radius: 10px;
        filter: drop-shadow(0px 0px 4px rgba(32, 32, 32, 0.7));
        overflow: hidden;
        position: relative;
    }

    .react-pdf__Document {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: auto;
        margin: 0;
    }

    .react-pdf__Page__canvas {
        max-width: 100%;
        height: auto !important;
    }

    .title {
        width: 100%;
        background-size: 100% 100%;
        color: #fff;
        outline: none;
        padding: 0rem .8rem 1.3rem .8rem;
        font-size: 2rem;
        background-color: transparent;
        border: 0;
        font-family: 'Cooper Black';
    }

    .items {
        padding: 10%;
    }

    .item {
        margin-bottom: 20px;
    }

    .container-link {
        position: relative;
        top: 0;
        left 0;
    }

    .container-link img {
        width: 100%;
    }

    .text-link {
        position: absolute;
        left: 50%;
        top: 50%;
        color: white;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    @media only screen and (max-width: 1024px) {
        max-width: 1024px;
    }
`;
