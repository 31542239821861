import React, { Component } from "react";
import { DivMessage } from "./styles";
// import { Link } from "react-router-dom";

class ChatMessage extends Component {
    render() {
        const class_name = this.props.user_id === this.props.message.user_from_id ? 'sent' : 'received';
        return (
            <DivMessage className="">
                <div className={class_name + " content"}>
                    {this.props.message.message}
                    <span className="time">{this.props.time}</span>
                </div>
            </DivMessage>
        );
    }
}

export default ChatMessage;
