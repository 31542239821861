import React, { Component } from "react";
import { getUser } from "../../services/auth";
import { FormStile, Container } from "./styles";
import SimpleMenu, { UnsignedMenu } from "../../components/Menu";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import CurrentLocation from '../../components/Maps'
import BtnSubmit from "../../assets/background_btn_submit_complaint.png";
import AbsenceSchool from "../../assets/absence.png";
import BabyCare from "../../assets/care.png";
import Cooking from "../../assets/cooking.png";
import Punishment from "../../assets/punish.png";
import Mistreatment from "../../assets/maustratos.png";
import UrbanWork from "../../assets/urban-work.png";
import FieldWork from "../../assets/campo.png";
import HomeResponsible from "../../assets/home.png";
import api from "../../services/api";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Geocode from "react-geocode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url, Tokenize } from "../../services/api";

Geocode.setApiKey("AIzaSyC9CdL5Ac4ujDvyG4u-FBXNQ82yHQfkkY4");

// set response language. Defaults to english.
Geocode.setLanguage("pt_BR");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("br");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

class ComplaintAnonymous extends Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(getUser());
        //console.log('usuario: ', user);
        this.state = {
            loading: false,
            user: user,
            text_header: user ? 'Denúncia Contra o Trabalho Infantil' : 'Denúncia Anônima Contra o Trabalho Infantil',
            user_id: user ? user.id : null,
            address: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            cx: '',
            cy: '',
            occurrence: '',
            occurrence_other: '',
            authorizes_contact: 0,
            file: '',
            latitude: '',
            longitude: '',
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            update_maps: false,
            selected: '',
            preview: false,
            previewFile: '',
            showCam: false,
            anonymous: false,
            showToast: ''
        }
    }

    isActive = (value) => {
        return 'occurrence '+((value===this.state.selected) ?'occurrence-active':'');
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log('componentDidUpdate');
        if (this.state.update_maps) {
            this.updateMaps();
            this.setState({update_maps: false});
        }
    }

    onMarkerClick = (props, marker, e) =>
    this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
    });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    handleComplaintSubmit = async e => {
        this.setState({loading: true});
        //console.log('dados: ', this.state);
        this.state.authorizes_contact = this.state.authorizes_contact ? 1 : 0;
        const { address, number, complement, neighborhood, city, occurrence, occurrence_other, authorizes_contact, file } = this.state;
        const cx = this.state.latitude;
        const cy = this.state.longitude;
        const user_id = this.state.user_id;
        try {
            await api.post("/complaints/add", { user_id, address, number, complement, neighborhood, city, cx, cy, occurrence, occurrence_other, authorizes_contact, file }).then(res=>{
                //console.log('retorno: ', res.data);
                this.setState({loading: false});
                if (file !== '') {
                    //console.log('fazer upload');
                    this.uploadFileData(res.data.complaint.id);
                } else {
                    //console.log('não fazer upload');
                }
                //this.props.history.push("/");
                //alert.show('Denúncia Registrada com Sucesso');
                toast('Denúncia Registrada com Sucesso');
                this.setState({
                    text_header: this.state.user ? 'Denúncia' : 'Denúncia Anônima',
                    user_id: this.state.user ? this.state.user.id : null,
                    address: '',
                    number: '',
                    complement: '',
                    neighborhood: '',
                    city: '',
                    cx: '',
                    cy: '',
                    occurrence: '',
                    occurrence_other: '',
                    authorizes_contact: 0,
                    file: '',
                    latitude: '',
                    longitude: '',
                    selected: '',
                    preview: false,
                    previewFile: '',
                    showCam: false,
                    anonymous: false,
                });
            }).catch(err=>{
                //console.log('erro retorno: ', err);
                let msg_error = '';
                if (err.response.data.error.username) {
                    //msg_error = "O Login " + username + " já existe, escolha outro e tente novamente.";
                }
                if (err.response.data.error.email) {
                    msg_error += msg_error !== '' ? '<br>' : '';
                    //msg_error += "O e-mail " + email + " já está cadastrado."
                }
                if (msg_error === '') {
                    this.setState({error: "Não foi possível enviar os dados. Tente novamente."});
                } else {
                    this.setState({error: msg_error});
                }
                this.setState({loading: false});
                window.scrollTo(0, 0)
            });

        } catch (err) {
            //console.log(err);
            this.setState({ error: "Ocorreu um erro ao registrar os dados." });
            this.setState({loading: false});
            window.scrollTo(0, 0)
        }
    };

    onFileChange = async (event) => {
		await this.setState({
			file: event.target.files[0],
            previewFile: URL.createObjectURL(event.target.files[0]),
            preview: true,
		});
        //console.log('file:', this.state.file);
	}

    handleTakePhoto = async (dataUri) => {
        await this.setState({
            file: dataUri,
            previewFile: dataUri,
            preview: true,
            showCam: false,
        });
        //console.log('file: ', dataUri);

        /*//console.log('photo_url: ', this.state.photo_url);
        this.uploadFileData();
        //console.log('rodou uploadFileData');*/
    }

    uploadFileData = (complaint_id) => {
		//event.preventDefault();
		this.setState({msg: ''});

		let data = new FormData();
		data.append('file', this.state.file);
        data.append('id', complaint_id);

        //console.log('photo: ', this.state.file);

		fetch(url + '/complaints/upload', {
			method: 'POST',
			body: data,
            headers: {'Accept': 'application/json;charset=UTF-8',
                        "Authorization": Tokenize()}
		}).then(response => {
            //console.log('retorno: ', response.data);
			this.setState({msg: "File successfully uploaded"});
		}).catch(err => {
            //console.log('retorno: ', err);
			this.setState({error: err});
		});

	}

    updateMaps = (event) => {
        //console.log('atualizar mapa');
        if (this.state.address !== '' && this.state.number !== '') {
            //console.log('buscar coordenadas');
            const address = this.state.address
                + ', ' + this.state.number
                + ' ' + this.state.neighborhood
                + ' ' + this.state.city
            Geocode.fromAddress(address).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    //console.log(lat, lng);
                    this.setState({
                        latitude: lat,
                        longitude: lng
                    })
                },
                error => {
                    console.error(error);
                }
            );
        } else {
            //console.log('não buscar coordenadas: ' + this.state.address + ' - ' + this.state.number);
        }
    }

    mapClicked = (lat, lng) => {
        //console.log('mapa clicado');
        this.setState({
            latitude: lat,
            longitude: lng
        });

        Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].address_components;
                //console.log(address);
                this.setState({
                    address: address[1].long_name,
                    number: address[0].long_name,
                    neighborhood: address[2].long_name,
                    city: address[3].long_name,
                });
            },
            error => {
                console.error(error);
            }
        );
    }

    /*previewImage = (input) => {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function(e) {
                $('#blah').attr('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]); // convert to base64 string
        }
    }*/

    render() {

        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;
        return (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    user_id: null,
                    address: this.state.address,
                    number: this.state.number,
                    complement: this.state.complement,
                    neighborhood: this.state.neighborhood,
                    city: this.state.city,
                    cx: this.state.cx,
                    cy: this.state.cy,
                    occurrence: this.state.occurrence,
                    occurrence_other: this.state.occurrence_other,
                    authorizes_contact: 0,
                    file: '',
                    anonymous: this.state.anonymous
                }}
                validationSchema={Yup.object().shape({
                    address: Yup.string().required('Endereço da Ocorrência Obrigatório'),
                    number: Yup.string().required('Número Obrigatório'),
                    neighborhood: Yup.string().required('Bairro Obrigatório'),
                    city: Yup.string().required('Gênero Obrigatório'),
                    occurrence: Yup.string().required('Tipo de Ocorrência Obrigatório. Selecione uma das imagens abaixo:'),
                })}
                onSubmit={fields => {
                    //console.log('Campos: ', fields);
                    this.setState({address: fields.address});
                    this.setState({number: fields.number});
                    this.setState({complement: fields.complement});
                    this.setState({neighborhood: fields.neighborhood});
                    this.setState({city: fields.city});
                    this.setState({occurrence: fields.occurrence});
                    this.setState({occurrence_other: fields.occurrence_other});
                    this.setState({authorizes_contact: fields.authorizes_contact ? 1 : 0});

                    this.handleComplaintSubmit();
                }}
            >
                {({errors, status, touched, submitForm}) => (
                    <div>
                        {this.state.loading && <div className="sweet-loading">
                            <PacmanLoader
                                css={override}
                                size={50}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>}
                        <div className={"toast " + this.state.showToast}>
                            <p>Denúncia enviada com sucesso.</p>
                        </div>
                        {this.state.user ? <SimpleMenu /> : <UnsignedMenu />}
                        <ToastContainer
                            hideProgressBar={true}
                            autoClose={false}
                            className='toast-container'
                            toastClassName="custom-toast"
                            progressClassName={css({
                                height: "2px"
                            })}
                        />
                        <Container>
                            <button type="button" className="btn-denuncia-anonima" alt="Denúncia Anônima">{this.state.text_header}</button>
                            {this.state.user && <div className="w-100 link-phone">
                                <a href="tel:3135912903">Ligar para (31) 3591-2903</a>
                            </div>}
                            <div className="col-sm-8 offset-sm-2 text-center">
                                <div className="map-title">
                                    Marque o local a ser fiscalizado.
                                </div>
                            </div>
                            <CurrentLocation
                                centerAroundCurrentLocation
                                google={this.props.google}
                                currentLocation={
                                    {lat:this.state.latitude,
                                    lng:this.state.longitude}
                                }
                                mapClicked={this.mapClicked}
                                onClickMap={true}
                            >
                                <Marker onClick={this.onMarkerClick} name={'Localização da Ocorrência'}
                                position={{lat:this.state.latitude,lng:this.state.longitude}} />
                                <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                    onClose={this.onClose}
                                >
                                    <div>
                                        <h4>{this.state.selectedPlace.name}</h4>
                                    </div>
                                </InfoWindow>
                            </CurrentLocation>

                            <div className="container">
                                <FormStile onSubmit={this.handleComplaintSubmit}>
                                    {this.state.error && <p dangerouslySetInnerHTML={{__html: this.state.error}}></p>}
                                    {this.state.user && <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <div className="form-check">
                                                <Field
                                                    name="anonymous"
                                                    id="anonymous"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    onChange={e => this.setState({
                                                        user_id: e.target.checked ? null : this.state.user.id,
                                                        anonymous: e.target.checked
                                                    })}
                                                />
                                                <label className="form-check-label" htmlFor="anonymous">
                                                    Fazer a Denúncia de Forma Anônima
                                                </label>
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="address"
                                                type="text"
                                                placeholder="Endereço (Rua / Av.)"
                                                className="background3"
                                                onBlur={e => {
                                                    this.setState({
                                                        address: e.target.value,
                                                        update_maps: true
                                                    })
                                                }}
                                            />
                                            <ErrorMessage name="address" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="number"
                                                type="text"
                                                placeholder="Número"
                                                className="background3"
                                                onBlur={e => {
                                                    this.setState({
                                                        number: e.target.value,
                                                        update_maps: true
                                                    })
                                                }}
                                            />
                                            <ErrorMessage name="number" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="complement"
                                                type="text"
                                                placeholder="Complemento"
                                                className="background3"
                                            />
                                            <ErrorMessage name="complement" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="neighborhood"
                                                type="text"
                                                placeholder="Bairro"
                                                className="background3"
                                                onBlur={e => {
                                                    this.setState({
                                                        neighborhood: e.target.value,
                                                        update_maps: true
                                                    })
                                                }}
                                            />
                                            <ErrorMessage name="neighborhood" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="city"
                                                type="text"
                                                placeholder="Cidade"
                                                className="background3"
                                                onBlur={e => {
                                                    this.setState({
                                                        city: e.target.value,
                                                        update_maps: true
                                                    })
                                                }}
                                            />
                                            <ErrorMessage name="city" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <label htmlFor="occurrence" className="background3 w-100">Notificação</label>
                                            <Field
                                                name="occurrence"
                                                type="hidden"
                                                placeholder="Ocorrência"
                                                className="background3"
                                            />
                                            <ErrorMessage name="occurrence" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>

                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-right">
                                            <img
                                                src={Cooking}
                                                className={this.isActive('cooking')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'cooking',
                                                        occurrence: 'Cozinhando'
                                                    })
                                                }}
                                                alt="Cozinhando"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-left">
                                            <img
                                                src={Mistreatment}
                                                className={this.isActive('mistreatment')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'mistreatment',
                                                        occurrence: 'Maus Tratos por Adultos'
                                                    })
                                                }}
                                                alt="Maus Tratos por Adultos"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-right">
                                            <img
                                                src={FieldWork}
                                                className={this.isActive('field_work')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'field_work',
                                                        occurrence: 'Trabalho no Campo'
                                                    })
                                                }}
                                                alt="Trabalho no Campo"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-left">
                                            <img
                                                src={Punishment}
                                                className={this.isActive('begging')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'begging',
                                                        occurrence: 'Abandono'
                                                    })
                                                }}
                                                alt="Abandono"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-right">
                                            <img
                                                src={AbsenceSchool}
                                                className={this.isActive('absence_school')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'absence_school',
                                                        occurrence: 'Ausência da Escola'
                                                    })
                                                }}
                                                alt="Ausência da Escola"
                                            />
                                            </div>
                                        </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-left">
                                            <img
                                                src={BabyCare}
                                                className={this.isActive('baby_care')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'baby_care',
                                                        occurrence: 'Cuidando do Bebê'
                                                    })
                                                }}
                                                alt="Cuidando do Bebê"
                                            />
                                            </div>
                                        </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-right">
                                            <img
                                                src={HomeResponsible}
                                                className={this.isActive('home_responsible')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'home_responsible',
                                                        occurrence: 'Responsável pela Casa'
                                                    })
                                                }}
                                                alt="Responsável pela Casa"
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-left">
                                            <img
                                                src={UrbanWork}
                                                className={this.isActive('urban-work')}
                                                onClick={() => {
                                                    this.setState({
                                                        selected: 'urban-work',
                                                        occurrence: 'Trabalho Urbano'
                                                    })
                                                }}
                                                alt="Trabalho Urbano"
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="occurrence_other"
                                                type="text"
                                                placeholder="Outros"
                                                className="background3"
                                                onChange={e => this.setState({
                                                    selected: '',
                                                    occurrence: e.target.value.length > 0 ? 'Outros' : '',
                                                    occurrence_other: e.target.value
                                                })}
                                            />
                                            <ErrorMessage name="occurrence_other" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    {this.state.user && <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <div className="form-check">
                                                <Field
                                                    name="authorizes_contact"
                                                    id="authorizes_contact"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                />
                                                <label className="form-check-label" htmlFor="authorizes_contact">
                                                    Autorizo Equipe do Circo de Todo Mundo fazer contato para mais informações
                                                </label>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.user && <div className="form-group row w-100 justify-content-center">
                                        <div className="col-8 text-center align-self-center btn-capture-image">
                                            <label htmlFor="file">Selecione um Arquivo</label>
                                            <input onChange={this.onFileChange} type="file" className="collapse" id="file" accept="image/*"></input>
                                        </div>
                                    </div>}
                                    {this.state.user && <div className="form-group row w-100 justify-content-center">
                                        <div className="col-8 text-center align-self-center btn-capture-image">
                                            <label onClick={e => this.setState({showCam:true,preview:false})}>Tire uma foto</label>
                                        </div>
                                    </div>}

                                    {this.state.showCam && <Camera
                                        onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                                    />}

                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            {this.state.preview && <img src={this.state.previewFile} className="preview" alt="Imagem" />}
                                        </div>
                                    </div>

                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <img src={BtnSubmit} className="btn" onClick={submitForm} alt="Enviar" />
                                        </div>
                                    </div>

                                </FormStile>
                            </div>
                        </Container>
                    </div>
                )}
            </Formik>
        );
    }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyC9CdL5Ac4ujDvyG4u-FBXNQ82yHQfkkY4')
})(ComplaintAnonymous);
//export default withRouter(ComplaintAnonymous);
