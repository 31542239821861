import React, { Component } from "react";
import Drawer from 'react-motion-drawer';
import { Nav, NavItems } from "./styles";
import { Link } from "react-router-dom";
import { removeUserDetails } from "../../services/auth";
import logo from "../../assets/whitelogo.png";

class SimpleMenu extends Component {
    state = {
    openLeft: false,
    openRight: false,
    drawerStyle: `
        {
          "background": "rgba(33, 78, 120, 1)",
          "boxShadow": "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"
        }`,
    relativeWidth: false,
    width: 300,
    noTouchOpen: false,
    noTouchClose: false,
    fadeOut: true,
  };

logoutMenu = () => {
  removeUserDetails();
  window.location = '/SignIn';
}

render() {
    const {
      drawerStyle: stringDrawerStyle,
      openRight,
      noTouchOpen,
      noTouchClose
    } = this.state;

    let drawerStyle = {}
    try {
      drawerStyle = JSON.parse(stringDrawerStyle)
    } catch (err) {
      console.error('Error parsing JSON: ', err)
    }

    const drawerProps = {
      overlayColor: "rgba(255,255,255,0.6)",
      drawerStyle
    };

  return (
      <div>
      <Drawer right width={this.state.width}
            {...drawerProps}
            open={openRight}
            onChange={open => this.setState({ openRight: open })}
            noTouchOpen={noTouchOpen}
            noTouchClose={noTouchClose} >
    { val =>{
              var per = val / 300;
              return (
                <div
                  style={{
                    backgroundColor: `rgba(33, 78, 120, ${per})`,
                    width: "100%",
                    height: "auto",
                    opacity: 1
                  }}
                >
                <NavItems>
                <ul className="ul-menu">
                  <li><Link to="/"><img src={logo} style={{maxHeight: "142px"}} alt="logo" /></Link></li>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/Profile">Perfil</Link></li>
                  <li><Link to="/Chat">Chat</Link></li>
                  <li><Link to="/Games">Jogos</Link></li>
                  <li><Link to="/Library">Biblioteca</Link></li>
                  <li><Link to="/Contact">Contato</Link></li>
                  <li><Link to="/CRAS">CRAS E CONSELHO TUTELAR</Link></li>
                  <li><Link to="/ComplaintAnonymous">Denúncia</Link></li>
                  <li><Link to="/Terms">Termos de Uso</Link></li>
                  <li><Link to="/About">Sobre o Projeto</Link></li>
                  <li><Link to="/Partners">Parceiros</Link></li>
                  <li><a href="#" onClick={this.logoutMenu}>Sair</a></li>
                </ul>
                </NavItems>
                </div>
              );
            }
    }
  </Drawer>

  <Nav>
    <nav>
      <div className="nav-wrapper">
        <Link to="/">
          <img src={logo} style={{maxHeight: "100%", float: "left"}} alt="logo" />
          <span className="title-nav-bar">Circo de Todo Mundo</span>
        </Link>
        <ul className="right">
          <li style={{ cursor: "pointer", height: "100%" }}>
            <span href="#"
              style={{ padding: 15 }}
              className=""
              onClick={() =>
                this.setState({ openRight: !openRight, openLeft: false })}
            >
              <i className="fa fa-bars" />
            </span>
          </li>
        </ul>
      </div>
      </nav>
    </Nav>

  </div>
  );
}
}

export class UnsignedMenu extends Component {
  state = {
    
  };

render() {
  return (
      <div>

  <Nav>
    <nav>
      <div className="nav-wrapper">
        <Link to="/">
          <img src={logo} style={{maxHeight: "100%", float: "right"}} alt="logo" />
          <span className="title-nav-bar">Circo de Todo Mundo</span>
        
          <ul className="left">
          <li style={{ height: "100%" }}>
              <span href="#"
                style={{ padding: 15 }}
                className=""
              >
                <i className="fa fa-arrow-left" />
              </span>
            </li>
          </ul>
        </Link>
      </div>
      </nav>
    </Nav>

  </div>
  );
}
}

export default SimpleMenu;