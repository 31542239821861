import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyC9CdL5Ac4ujDvyG4u-FBXNQ82yHQfkkY4",
    authDomain: "circo-todo-mundo-trab-infantil.firebaseapp.com",
    databaseURL: "https://circo-todo-mundo-trab-infantil.firebaseio.com",
    projectId: "circo-todo-mundo-trab-infantil",
    storageBucket: "circo-todo-mundo-trab-infantil.appspot.com",
    messagingSenderId: "696404793643",
    appId: "1:696404793643:web:c2903ae781a8e443304d6e",
    measurementId: "G-3VHQ4LXSLF"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

var isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
               navigator.userAgent &&
               navigator.userAgent.indexOf('CriOS') === -1 &&
               navigator.userAgent.indexOf('FxiOS') === -1;

if(!isSafari) {
    const messaging = firebase.messaging();

    messaging.usePublicVapidKey("BEBCv-EzhDdWfHhV9RRhpLd39-1eRasuSc0I12BTm2EHoaK_PS4BlbzYPZ_BYgQ9rFIBJeV4kecZmul1y3ief24");

    messaging.requestPermission()
        .then(function() {
            //console.log("Have permission");
            return messaging.getToken();
        })
        .then(function(token) {
            //console.log(token);
            localStorage.setItem("localToken", token);
        })
        .catch(function(err) {
            //console.log("Error occurred");
        })

} else {
    console.error("Detected Safari browser... Firebase push notifications disabled.")
}
export default firebase;