import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { isAuthenticated } from "./services/auth";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import RecoverPassword from "./pages/RecoverPassword";
import ComplaintAnonymous from "./pages/ComplaintAnonymous";
import Profile from "./pages/Profile";
import Terms from "./pages/Terms";
import About from "./pages/About";
import Games from "./pages/Games";
import GameFiles from "./pages/Games/core";
import Rank from "./pages/Games/rank";
import Contact from "./pages/Contact";
import Chat from "./pages/Chat";
import ChatConversation from "./pages/ChatConversation";
import Library from "./pages/Library";
import View from "./pages/view";
import Partners from "./pages/Partners";
import CRAS from "./pages/CRAS";


const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/SignIn", state: { from: props.location } }} />
      )
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/SignIn" component={SignIn} />
      <Route path="/SignUp" component={SignUp} />
      <Route path="/RecoverPassword" component={RecoverPassword} />
      <Route path="/ComplaintAnonymous" component={ComplaintAnonymous} />
      <Route path="/Terms" component={Terms} />
      <Route exact path="/About" component={About} />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute path="/Profile" component={Profile} />
      <PrivateRoute exact path="/CRAS" component={CRAS} />
      <PrivateRoute exact path="/Partners" component={Partners} />
      <PrivateRoute exact path="/Games" component={Games} />
      <PrivateRoute exact path="/Games/core/:gameId" component={GameFiles} />
      <PrivateRoute exact path="/Games/rank/:id/:gameId" component={Rank} />
      <PrivateRoute path="/Contact" component={Contact} />
      <PrivateRoute path="/Chat" component={Chat} />
      <PrivateRoute path="/ChatConversation/:id/:nick/:token" component={ChatConversation} />
      <PrivateRoute path="/Library" component={Library} />
      <PrivateRoute exact path="/view/:link" component={View} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
