import styled from "styled-components";
import backgroundChat from "../../assets/background_chat.jpg";
import backgroundInput5 from "../../assets/background_input_5.png";
// import BackgroundSearch from "../../assets/background_input_password.png";

export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundChat});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 60px;
    min-height: 100vh;
    
    .main {
        padding-top: 10px;
        overflow: scroll;
        overflow-x: hidden;
        padding-bottom: 10px;
        background-color: transparent;
        height: 70vh;
        position: relative;
    }

    .header {
        display:flex;
        margin: auto;
        width:100%;
        text-align:center;
        padding-top: 5px;
        border-bottom: 1px solid #fff;
    }

    .footer {
        display:block;
        width:100%;
        max-height: calc(100vh / 100 * 16);
        border-top: 1px solid #fff;
        padding-top: 10px;
        margin: auto;
        max-width: 583px;
    }

    .btn-scroll {
        display: none;
        position: fixed;
        bottom: 20vh;
        right: 5vw;
    }

    .title {
        display:inline-block;
        width: 100%;
        background-size: cover;
        color: #fff;
        outline: none;
        padding: 0rem .8rem 0rem .8rem;
        font-size: 2rem;
        background-color: transparent;
        border: 0;
        font-family: 'Cooper Black';
        margin-right: 20px;
    }

    .id_to_name {
        display:inline-block;
        width: 100%;
        background-size: cover;
        color: #fff;
        outline: none;
        padding: 0rem .8rem 0rem .8rem;
        font-size: 1.5rem;
        background-color: transparent;
        border: 0;
        margin-left: 20px;
        margin-top: 5px;
        font-family: 'Cooper Black';
    }

    .left {
        float: left;
        max-width: 75%;
        width: 100%;
        padding: 0 10px;
    }

    .right {
        float: right;
        max-width: 25%;
        padding: 0 10px;
    }

    .textarea {
        background:url(${backgroundInput5}) no-repeat right top;
        background-size: 100% 100%;
        background-color: transparent;
        border-radius: 5px;
        color: #000 !important;
        width: 100%;
        border: 0;
        outline: none;
        z-index:0;
        padding: 3px 10px;
        width: 100%;
        height: 10vh;
        margin: 0;
    }

    .img-send {
        width: 100%;
        margin-top: 30%;
        position: relative;
        left: 35px;
        transform: translate(-50%, -50%);
    }
`;
