import styled from "styled-components";
import backgroundContact from "../../assets/background_contact.jpg";
import backgroundInput1 from "../../assets/background_input_1.png";
import backgroundInput2 from "../../assets/background_input_2.png";
import backgroundInput4 from "../../assets/background_input_4.png";
export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundContact});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 60px;

    .title {
        width: 100%;
        background-size: 100% 100%;
        color: #fff;
        outline: none;
        padding: 1rem .8rem 1.3rem .8rem;
        font-size: 2rem;
        background-color: transparent;
        border: 0;
        font-family: 'Cooper Black';
    }

    .contact-data {
        width: 80%;
        margin-left: 10%;
        background-color: rgb(45,45,45,0.9);
        border-radius: 10px;
        padding: 3%;
        color: white;
    }

    input[type="button"]:focus,
    input[type="button"]:active {
        -moz-outline-style: none;
        outline:none;
        outline:0;
    }

    .occurrence {
        max-width: 60%;
    }

    .occurrence-active {
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    .invalid-feedback {
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    .preview {
        max-width: 80%;
    }

    .btn-capture-image {
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
        padding: 5px 0;
    }

    label {
        margin: 0;
    }

    .link-phone {
        margin-top: 20px;
    }

    a[href^="tel:"] {
        color: #214e78;
        text-decoration: none;
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
        padding: 5px;
    }
`;

export const FormStile = styled.form`
    width: 100%;
    background: transparent;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        color: #ff3333;
        margin-bottom: 15px;
        padding: 10px;
        width: 100%;
        text-align: center;
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    input[type="text"], input[type="date"], input[type="password"] {
        flex: 1;
        height: 46px;
        padding: 10px 0;
        margin-top: 10px;
        color: #214e78;
        font-size: 1.5em;
        width: 100%;
        border: 0;
        &::placeholder {
            color: #999;
        }
        text-align: center;
        ::placeholder {
            color: #214e78;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #214e78;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: #214e78;
        }
        outline: none;
    }

    .form-check-label {
        color: #fff;
    }

    .background1 {
        background:url(${backgroundInput1}) no-repeat right top;
        background-size: 100% 100%;
    }

    .background2 {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
    }

    .label_birth_date {
        display: inline-block;
        width: 90%;
        padding: 10px;
        margin-bottom: -10px;
    }

    button {
        color: #fff;
        font-size: 16px;
        background: #fc6963;
        height: 56px;
        border: 0;
        border-radius: 5px;
        width: 100%;
    }
    hr {
        margin: 20px 0;
        border: none;
        border-bottom: 1px solid #cdcdcd;
        width: 100%;
    }
    a {
        font-size: 16;
        font-weight: bold;
        color: #999;
        text-decoration: none;
    }
    .btn {
        width: 70%;
    }

    .textarea {
        background:url(${backgroundInput4}) no-repeat right top;
        background-size: 100% 100%;
        backgroundColor: #fff;
        border-radius: 5px;
        color: #000 !important;
        width: 80%;
        border: 0;
        outline: none;
        z-index:0;
    }

    .label-textarea {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
        color: #fff !important;
        display: inline-block;
        width: 90%;
        padding: 10px;
        margin-bottom: -10px;
        z-index:1000;
    }
`;
