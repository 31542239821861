import React, { Component } from 'react';
import { Link } from "react-router-dom";
import RotateGif from '../../../assets/rotate.gif';
// import SimpleMenu from '../../../components/Menu/index.js';
import BackBtn from '../../../assets/voltar.png';
import { getUser } from '../../../services/auth';
import './style.css';
import { urlGames } from "../../../services/api";

class GameFile extends Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(getUser());
        const gameid = this.props.match.params.gameId;
        const btnClass = "backBtn ";
        ////console.log(urlGames);

        this.state = {
            user: user,
            gameId: gameid,
            btnClass: btnClass,
            rotated: true,
            isPortrait: false
        }
    }
    

    componentDidMount() {
        function isMobileDevice() {
            return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
        };
        if(this.state.gameId === "spinner") {
            this.setState({rotated: window.orientation!==0 && isMobileDevice() ? false : true, isPortrait: true});
        } else {
            this.setState({rotated: window.orientation!==90 && isMobileDevice() ? false : true});
        }
    }

    render() {
        function isMobileDevice() {
            return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
        };
        const self = this;
        
        window.addEventListener("orientationchange", function() {
            if(self.state.gameId === "spinner") {
                self.setState({rotated: window.orientation!==0 && isMobileDevice() ? false : true});
            } else {
                self.setState({rotated: window.orientation!==90 && isMobileDevice() ? false : true});
            }
            window.location.reload();
        }, false);

        const url = urlGames + this.state.gameId + "/index.php?user_id=" + this.state.user.id;

        return(
            <div>
                <Link to="/Games"><img src={BackBtn} className={this.state.gameId === 'chuva' ? 'backBtn bottom' : this.state.gameId === 'bubblehit' ? 'backBtn bottombubble' : 'backBtn top'} alt="Back Button"/></Link>
                
                <div className="game">
                    <div className="rotateDiv" id={this.state.rotated ? 'hidden' : ''}><img src={RotateGif} alt="Rotate Gif" /></div>
                    <iframe title="Game" src={url} id={this.state.isPortrait ? 'portrait' : 'landscape'} className="gameFrame"></iframe>
                </div>
            </div>
        );
    }
}

export default GameFile;