import React, { Component } from "react";
import { Link } from "react-router-dom";
import { url } from "../../services/api";
// import BackgroundItem1 from "../../assets/library_item_1.png";
// import BackgroundItem2 from "../../assets/library_item_2.png";
// import BackgroundItem3 from "../../assets/library_item_3.png";
// import BackgroundItem4 from "../../assets/library_item_4.png";
// import BackgroundItem5 from "../../assets/library_item_5.png";
// import BackgroundItem6 from "../../assets/library_item_6.png";

class LibraryItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: this.props.item,
        }
    }

    render() {
        // const backgrounds = [
        //     BackgroundItem1,
        //     BackgroundItem2,
        //     BackgroundItem3,
        //     BackgroundItem4,
        //     BackgroundItem5,
        //     BackgroundItem6
        // ]
        // const rand = Math.floor(1 + Math.random() * (6 - 1));
        return (
            <div className="col-6 item">
                <Link to={"/view/" + window.btoa(this.state.item.file_url)}>
                        <div className="container-link">
                            <img src={url + "/libraries/view/" + this.state.item.cover_url} alt={this.state.item.name} />
                            {/* <div className="text-link">
                                {this.state.item.name}
                            </div> */}
                        </div>
                </Link>
            </div>
        );
    }
}

export default LibraryItem;
