import styled from "styled-components";
// import BackgroundInput3 from "../../assets/background_input_3.png";

export const DivMessage = styled.div`

    .content {
        justify-content: center;
        text-align: left;
        margin: 5px;
        border-radius: 15px;
        border: 2px solid;
        width: 85%;
        padding: 10px;
    }

    .sent {
        float: right;
        background-color: #3b54a4;
        color: white;
        border-color: #fff;
    }

    .received {
        float: left;
        background-color: #82b927;
        color: blue;
        border-color: #fff;
    }

    .notify {
        font-size: 72%;
        float: right;
    }

    .time {
        font-size: 72%;
        float: right;
    }
`;
