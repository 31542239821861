import React, {Component} from 'react';
import { Link } from "react-router-dom";
import SimpleMenu from '../../components/Menu/index.js';
import { getUser } from '../../services/auth';
import './style.css';
import rankImg from '../../assets/rankIcon.png';
import bubble from '../../assets/bubble.jpg';
import tangram from '../../assets/tangram.jpg';
import quiz from '../../assets/quiz.jpg';
import spinner from '../../assets/spinner.jpg';
import chuva from '../../assets/chuva.jpg';

const Header = () => <SimpleMenu />;
const Url = (game) => "/Games/rank/" + JSON.parse(getUser()).id + "/" + game;

const KidGames = () => 
    <div className="verticalDrawer d-flex flex-column">
        <div id="gameDiv"><Link to={Url(1)}><img src={rankImg} alt="Ranking" id="rank" /></Link><Link to='/Games/core/quiz'><img src={quiz} alt="Quiz" id="game" /></Link></div>
        <div id="gameDiv"><Link to={Url(2)}><img src={rankImg} alt="Ranking" id="rank" /></Link><Link to='/Games/core/spinner'><img src={spinner} alt="Spinner" id="game" /></Link></div>
        <div id="gameDiv"><Link to={Url(3)}><img src={rankImg} alt="Ranking" id="rank" /></Link><Link to='/Games/core/chuva'><img src={chuva} alt="Chuva" id="game" /></Link></div>
    </div>;

const TeenagerGames = () =>
    <div className="verticalDrawer d-flex flex-column">
        <div id="gameDiv"><Link to={Url(4)}><img src={rankImg} alt="Ranking" id="rank" /></Link><Link to='/Games/core/bubblehit'><img src={bubble} alt="Bubble Hit" id="game" /></Link></div>
        <div id="gameDiv"><Link to={Url(5)}><img src={rankImg} alt="Ranking" id="rank" /></Link><Link to='/Games/core/tangram'><img src={tangram} alt="Tangram" id="game" /></Link></div>
    </div>;

class Games extends Component {

    constructor(props) {
        super(props);
        const user = JSON.parse(getUser());
        const age = calculateDate(user.birth_date);

        this.state = {
            user: user,
            age: age.years
        }
    }

    render() {
        return (
            <div>
                <Header />
                <div className="body">
                    {this.state.age <= 9 && <KidGames />}
                    {this.state.age >= 10 && <TeenagerGames />}
                </div>
                
            </div>
        );
    }
}

function calculateDate(calcDate) {
    let now = new Date();
    let past = new Date(calcDate);
    let diff = Math.abs(now.getTime() - past.getTime());
    const years = Math.ceil(diff / (1000 * 60 * 60 * 24 * 30 * 13));

    return {years};
}

export default Games;