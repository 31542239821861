import styled from "styled-components";
import backgroundLogin from "../../assets/background_signin.png";
import backgroundInputUsername from "../../assets/background_input_username.png";
import backgroundInputPassword from "../../assets/background_input_password.png";
import backgroundBtnEntrar from "../../assets/btn_entrar.png";
import backgroundBtnDenunciaAnonima from "../../assets/btn_denuncia_anonima.png";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-image: url(${backgroundLogin});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Form = styled.form`
  width: 100%;
  background: transparent;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo {
    width: 50%;
    margin: 10% 0 10px;
    max-width: 300px;
  }
  p {
    color: #a30a0a;
    font-weight: bold;
    background-color: rgba(202, 202, 202, 0.9);
    border-radius: 10px;
    margin-bottom: 15px;
    padding: 3%;
    width: 100%;
    text-align: center;
  }
  input {
    margin-bottom: 8px;
    padding: 8px 10px 12px 10px;
    color: yellow;
    font-size: 15px;
    width: 70%;
    height: 100%;
    border: 0;
    &::placeholder {
      color: #999;
    }
    text-align: center;
    ::placeholder {
        color: yellow;
        opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: yellow;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
     color: yellow;
    }
    outline: none;
  }
  
  input:last-child {
    margin-bottom: 0px;
  }


.username {
    background:url(${backgroundInputUsername}) no-repeat right top;
    background-size: 100% 100%;
}

.password {
    background:url(${backgroundInputPassword}) no-repeat right top;
    background-size: 100% 100%;
}

  button {
    background-color: transparent;
    border: 0;
  }

  .btn-login {
      width: 70%;
      background:url(${backgroundBtnEntrar}) no-repeat right top;
      background-size: 100% 100%;
      color: yellow;
      outline: none;
      padding: .375rem .75rem;
  }

  .btn-denuncia-anonima {
      width: 80%;
      background:url(${backgroundBtnDenunciaAnonima}) no-repeat right top;
      background-size: 100% 100%;
      color: #fff;
      outline: none;
      padding: 1rem .8rem 1.3rem .8rem;
      margin-top: 20%;
      font-size: 1.5rem;
  }

  input[type="button"]:focus,
  input[type="button"]:active {
      -moz-outline-style: none;
	outline:none;
	outline:0;
  }

  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #cdcdcd;
    width: 100%;
  }
  a {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    text-decoration: none;
    margin-bottom: 14px;
  }
`;
