
export const setUser = user => {
    localStorage.setItem('user',user);
};

export const getUser = () => {
    return localStorage.getItem('user');
};

export const setUserDetails = (username, password) => {
    localStorage.setItem('userDetailsUsername', window.btoa(username));
    localStorage.setItem('userDetailsPassword', window.btoa(password))
}

export const getUserDetails = (isUsername) => {
    if(isUsername)
        return localStorage.getItem('userDetailsUsername');
    else
        return localStorage.getItem('userDetailsPassword');
}

export const removeUserDetails = () => {
    localStorage.removeItem('userDetailsUsername');
    localStorage.removeItem('userDetailsPassword');
}

export const setPopup = () => {
    localStorage.setItem('isPopupHandled', 'handled');
};

export const getPopup = () => {
    return localStorage.getItem('isPopupHandled');
};

export const TOKEN_KEY = "@circo-api-token";
export const isAuthenticated = () => {
    const user = localStorage.getItem('user');
    //console.log('Retornar: ', user);
    return user;
};
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const login = token => {
    localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
    //console.log('sair');
    localStorage.removeItem('user');
};
export const setLibrary = library => {
    localStorage.setItem('library',library);
}
export const getLibrary = () => {
    return localStorage.getItem('library');
}
export function setRank(num, list) {
    localStorage.setItem('list' + num, list);
};

export function getRank(num) {
    return localStorage.getItem('list' + num);
};

export function setChat(num, list) {
    localStorage.setItem('chat' + num, list);
};

export function getChat(num) {
    return localStorage.getItem('chat' + num);
};

export const setUserlist = list => {
    localStorage.setItem('userL', list);
};

export const getUserlist = () => {
    return localStorage.getItem('userL');
};