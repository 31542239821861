import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SimpleMenu from "../../components/Menu";
import ChatMessage from "../../components/ChatMessage";
import { Container } from "./styles";
import api from "../../services/api";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";
import { getUser } from "../../services/auth";
import backgroundBtnSubmint from "../../assets/background_btn_submit_2.png";
import { setChat, getChat } from '../../services/auth';
// import ScrollToBottom from 'react-scroll-to-bottom';
import firebase from '../../firebase';

class ChatConversation extends Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();

        const user = JSON.parse(getUser());
        const {id} = this.props.match.params;
        const {nick} = this.props.match.params;
        const {token} = this.props.match.params;

        //console.log('id: ', id);
        this.state = {
            loading: true,
            conversation: {},
            timeArr: {},
            user: user,
            id_to: id,
            nick_to: "> " + nick,
            message: '',
            otherToken: token
        }

        //console.log(this.state.otherToken);

        this.conversationHandler();

        this.listenMessages();
    }

    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth' });
    }

    componentDidMount() {
        //this.scrollToBottom();
        this.myRef.current.scrollTo(0, this.myRef.current.clientHeight * this.state.conversation.length / 4);
    }

    componentDidUpdate() {
        //this.scrollToBottom();
        this.myRef.current.scrollTo(0, this.myRef.current.clientHeight * this.state.conversation.length / 4);
    }

    conversationHandler = () => {
        api.get("/chats/getConversation/" + this.state.user.id + "/" + this.state.id_to).then(res=>{
            //console.log(res.data);
            let times = new Array();
            {Object.keys(res.data.conversation).map((e, i) => {
                let date = new Date(res.data.conversation[i].created);
                let minutes = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
                times.push(Math.abs(parseInt(date.getHours()-3)) + ":" + minutes);
                })
            }
            this.setState({
                loading: false,
                conversation: res.data.conversation,
                timeArr: times
            });
            setChat(this.state.id_to, JSON.stringify(this.state.conversation));
        }).catch(err=>{
            //console.log('Erro ao buscar os dados da conversa: ', err);
            this.setState({loading: false, conversation: JSON.parse(getChat(this.state.id_to))});
        });
    }

    sendMessage = () => {
        this.setState({loading: true});
        const message = this.state.message;
        const user_from_id = this.state.user.id;
        const user_to_id = this.state.id_to;

        if (this.state.message !== '') {
            try {
                
                const requestOptions = {
                    method: 'POST',
                    headers: { 
                        'Content-Type': 'application/json', 
                        'Authorization': 'key=AAAAoiT12Ss:APA91bErlYHI_VmNp_B1l7K6W50ys6nhnw2h3XSaMODMkOS0tsWlbfoNoyJw7892oscnb_volQ_avWKX7h7T333lpXGkqVNG_gW9QvcShinSsGhxDASRHZiVreZp_VhkEk_BGrZGnGs_' 
                    },
                    body: JSON.stringify({ 
                        "notification": {
                            "title": "CTM: Você recebeu uma mensagem!",
                            "body": this.state.nick_to + ": " + this.state.message
                        },
                            "to": this.state.otherToken
                    })
                }

                fetch('https://fcm.googleapis.com/fcm/send', requestOptions)
                    .then(response => response.json())
                    .then(data => this.setState({ postId: data.id }));
                
                
                api.post("/chats", { user_from_id, user_to_id, message }).then(res=>{
                    
                    let times = Array.from(this.state.timeArr);
                    let date = new Date(res.data.chat.created);
                    let minutes = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
                    times.push(Math.abs(parseInt(date.getHours()-3)) + ":" + minutes);
                    
                    //console.log('retorno: ', res.data.chat);
                    let conversation = this.state.conversation;
                    conversation.push(res.data.chat);
                    this.setState({
                        error: "",
                        loading: false,
                        conversation: conversation,
                        message: "",
                        timeArr: times
                    });
                    setChat(this.state.id_to, JSON.stringify(this.state.conversation));

                    firebase
                        .database()
                        .ref("messages/")
                        .child(user_to_id)
                        .push({
                            text: message,
                            id_from: user_from_id
                        })
                    
                }).catch(err=>{
                    //console.log('erro retorno: ', err);
                    this.setState({
                        error: "Mensagem não enviada. Tente novamente.",
                        loading: false,
                    });
                });
            } catch (err) {
                //console.log(err);
                this.setState({
                    error: "Ocorreu um erro ao enviar a mensagem.",
                    loading: false,
                });
            }
        } else {
            this.setState({loading: false});
        }
    }

    listenMessages() {
        const user_from_id = this.state.user.id;
        const messagesDB = firebase.database().ref("messages/"+user_from_id);
        messagesDB.orderByKey().limitToLast(1).on("child_added", snapshot => {
            this.conversationHandler();
        })
    }
    render() {
        
        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;

        return(
            <div>
                <SimpleMenu />
                {this.state.loading && <div className="sweet-loading">
                    <PacmanLoader
                        css={override}
                        size={50}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>}
                <Container>
                    <header className="header">
                        <h1 className="id_to_name text-left">{this.state.nick_to}</h1>
                        <h1 className="title text-right">Chat</h1>
                    </header>

                    <div ref={this.myRef} className="main">
                        {Object.keys(this.state.conversation).map((e, i) => {
                            if(this.state.conversation.length > 200 && i < this.state.conversation.length - 200) {
                                return false; 
                            } else {
                                return <ChatMessage key={i}
                                    time={this.state.timeArr[i]}
                                    message={this.state.conversation[i]}
                                    user_id={this.state.user.id}
                                />
                            }
                        })
                        }
                        <div ref={el => { this.el = el; }} />
                    </div>

                    <footer className="footer">
                        <div className="left">
                            <textarea
                                className="textarea"
                                rows="3"
                                onChange={(e) => this.setState({message: e.target.value})}
                                value={this.state.message}
                            />
                        </div>
                        <div className="right">
                            <img src={backgroundBtnSubmint} className="img-send" onClick={this.sendMessage} />
                        </div>
                    </footer>
                </Container>
            </div>
        )
    }
}

export default withRouter(ChatConversation);
