import styled from "styled-components";
import backgroundProfile from "../../assets/background_profile.jpg";
import backgroundInput1 from "../../assets/background_input_1.png";
import backgroundInput2 from "../../assets/background_input_2.png";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    background-image: url(${backgroundProfile});
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    padding-top: 30px;

    .title {
        margin-top: 60px;
        margin-bottom: 0;
        background-color: rgba(32, 32, 32, 0.9);
        padding: 5%;
        color: white;
        border-radius: 10px;
    }
`;

export const FormStile = styled.form`
    width: 100%;
    background: transparent;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        color: #ff3333;
        margin-bottom: 15px;
        padding: 10px;
        width: 100%;
        text-align: center;
        background-color: rgb(255,255,255,0.7);
        border-radius: 5px;
    }

    .password, .birth_date {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
        color: #fff !important;
        ::placeholder {
            color: #fff !important;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #fff !important;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: #fff !important;
        }
    }

    input[type="text"], input[type="date"], input[type="password"] {
        flex: 1;
        height: 46px;
        margin-bottom: 15px;
        padding: 10px 10px;
        color: yellow;
        font-size: 1.5em;
        width: 90%;
        border: 0;
        &::placeholder {
            color: #999;
        }
        text-align: center;
        ::placeholder {
            color: yellow;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: yellow;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: yellow;
        }
        outline: none;
    }

    .birth_date {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
        border: 0px;
        color: #fff !important;
        height: 45px;
        padding-left: 5%;
        padding-right: 10%;
        ::placeholder {
            color: #fff !important;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #fff !important;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: #fff !important;
        }
    }

    .react-datepicker-input, .react-datepicker-input:hover, .react-datepicker-input:active, .react-datepicker-input:focus, .react-datepicker-input.is-open {
        background: transparent;
        border: 0px;
        color: white;
        font-size: 21px;
        text-align: center;
        width: 100%;
    }
    
    .react-datepicker-input.has-value input, .react-datepicker-input input {
        color: white !important;
        font-size: 21px;
        font-weight: 400;
        text-align: center;
        width: 100%;
        padding: 0;
        padding-top: 1%;
    }

    .icon-rc-datepicker {
        transition: all 0.2s;
        margin-top: 78%;
        font-size: 20px;
        color: white;
    }

    .icon-rc-datepicker:hover {
        transition: all 0.2s;
        transform: scale(1.2, 1.2);
    }

    .boy, .girl {
        max-width: 50px;
    }

    .form-check-label {
        color: #fff;
    }

    .background1 {
        background:url(${backgroundInput1}) no-repeat right top;
        background-size: 100% 100%;
    }

    .background2 {
        background:url(${backgroundInput2}) no-repeat right top;
        background-size: 100% 100%;
    }

    .email {
        width: 100% !important;
        font-size: 1rem !important;
    }

    .username {
        margin-top: 5%;
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: yellow;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: yellow;
    }

    .label_birth_date {
        display: inline-block;
        width: 90%;
        padding: 10px;
        margin-bottom: -10px;
    }

    button {
        color: #fff;
        font-size: 16px;
        background: #fc6963;
        height: 56px;
        border: 0;
        border-radius: 5px;
        width: 100%;
    }
    hr {
        margin: 20px 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.7);
    }
    a {
        font-size: 16;
        font-weight: bold;
        color: #999;
        text-decoration: none;
    }
    .btn {
        width: 70%;
    }
`;
