import styled from "styled-components";

export const Nav = styled.div`
    position: fixed;
    width: 100%;
    max-width: 583px;
    height: 60px;
    z-index: 1000;
    font-family: 'Roboto Slab', serif;
    font-weight: 300;

    .nav-wrapper {
        text-align: center;
        font-size: 24px;
        background-color: #214e78;
        height: 60px;
    }

    nav {
        color: #fff;
        width: 100%;
        line-height: 56px;
    }
    
    nav a {
        color: #fff;
    }

    a, a:hover, a:active, a:visited, a:focus {
        text-decoration:none;
    }

    ul {
        margin: 0;
        list-style-type: none;
        padding: 0;
    }

    .right {
        float: right !important;
    }

    .left {
        float: left !important;
    }

    @media only screen and (max-width: 1024px) {
        max-width: 1024px;
    }
`;

export const NavItems = styled.div`
    font-family: 'Roboto Slab', serif;

    .ul-menu {
        padding: 15px;
        list-style-type: none;
    }

    li:first-child {
        border-bottom: 0px;
    }

    li:last-child {
        text-align: center;
        margin-top: 20%;
    }

    li {
        font-size: 1.3rem;
        border-bottom: 1px solid white;
        margin-top: 10px;
    }

    .ul-menu > li > a {
        color:#fff;
    }

    a, a:hover, a:active, a:visited, a:focus {
        text-decoration:none;
    }
`;
