import styled from "styled-components";
import backgroundTerms from "../../assets/background_terms.jpg";

export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundTerms});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 80px;
    font-family: 'Roboto Slab', serif;

    #check {
        margin-top: 10px;
        margin-right: 15px;
    }

    .terms {
        margin: 0 15px;
        padding: 15px;
        color: white;
        text-align: justify;
        text-justify: inter-word;
        background-color: rgba(0,0,0,0.6);
        padding: 4%;
    }

    .img-header img {
        margin-top: 20px;
        max-width: 50%;
    }

    .link {
        margin: 15px 15px 0 15px;
        padding: 15px;
    }
`;
