import React, { Component } from "react";
import { DivUser } from "./styles";
import { Link } from "react-router-dom";
import notify from "../../assets/notificacao.png";

class UsersChat extends Component {
    render() {
        return (
            <div className="col-10 offset-1">
                <Link
                    to={"/ChatConversation/" + this.props.item.id + "/" + this.props.item.username + "/" + this.props.item.fcm_token}
                    className="container-link" onClick={this.props.click}
                    >
                    <DivUser>
                        
                        {this.props.item.username}
                        <img src={notify} alt="notification" style={{maxWidth: "3vw", float: "right", display: this.props.notifyDisplay}} />
                        
                    </DivUser>
                </Link>
            </div>
        );
    }
}

export default UsersChat;
