import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { setLibrary, getLibrary } from '../../services/auth';
import SimpleMenu from "../../components/Menu";
import LibraryItem from "../../components/LibraryItem";
import { Container } from "./styles";
import api from "../../services/api";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";

class Library extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            libraries: {}
        }

        api.get("/libraries").then(res=>{
            this.setState({
                loading: false,
                libraries: res.data.libraries
            });
            setLibrary(JSON.stringify(this.state.libraries));
        }).catch(err=>{
            //console.log('Erro ao buscar os dados da biblioteca');
            this.setState({
                loading: false,
                libraries: JSON.parse(getLibrary())
            });
        });
    }

    render() {

        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;

        return (
            <div>
                <SimpleMenu />
                {this.state.loading && <div className="sweet-loading">
                    <PacmanLoader
                        css={override}
                        size={50}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>}
                <Container>
                    <div className="items">
                        <div className="row text-center">
                            <h1 className="title">Biblioteca</h1>
                            {Object.keys(this.state.libraries).map((e, i) => {

                                return <LibraryItem key={i}
                                    item={this.state.libraries[i]}
                                />
                            })
                            }
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withRouter(Library);
