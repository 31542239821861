import styled from "styled-components";
import BackgroundInput3 from "../../assets/background_input_3.png";

export const DivUser = styled.div`
    justify-content: center;
    background-image: url(${BackgroundInput3});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
`;
