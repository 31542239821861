import axios from "axios";
import { getToken } from "./auth";

// const api = axios.create({
//   baseURL: "https://localhost:8765/api",
//   headers: {'Accept': 'application/json;charset=UTF-8'}
// });
export const url = "https://admin.circodetodomundo.org.br/api";
export const urlGames = "https://circodetodomundo.org.br/games/";

const api = axios.create({
  baseURL: url,
  headers: {'Accept': 'application/json;charset=UTF-8'}
});

api.interceptors.response.use(function (response) {
  return response;
  }, function (error) {
    ////console.log(error.response);
      if (500 === error.response.status && error.response.data.message === "Expired token") {
        ////console.log("Token expirado, redirecionando...");
        window.location = '/SignIn';
      } else {
          return Promise.reject(error);
      }
});

// Intercepta uma requisição antes de acontecer e se existir um token, adiciona no cabeçalho
api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export function Tokenize() {
  const token = getToken();
  if (token) {
    return `Bearer ${token}`;
  }
}

export default api;
