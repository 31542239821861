import styled from "styled-components";
import backgroundLibrary from "../../assets/background_library.jpg";

export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundLibrary});
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat no-repeat;
    text-align: center;
    padding-top: 60px;
    min-height: 100vh;

    .title {
        width: 100%;
        background-size: 100% 100%;
        color: #fff;
        outline: none;
        padding: 0rem .8rem 1.3rem .8rem;
        font-size: 2rem;
        background-color: transparent;
        border: 0;
        font-family: 'Cooper Black';
    }

    .items {
        padding: 10%;
    }

    .item {
        margin-bottom: 20px;
    }

    .container-link {
        position: relative;
        top: 0;
        left 0;
    }

    .container-link img {
        width: 100%;
    }

    .text-link {
        position: absolute;
        left: 50%;
        top: 50%;
        color: white;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

`;
