import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
import { url } from "../../services/api";
import { Container } from "./styles";
import { Page, Document, pdfjs } from 'react-pdf';
import SimpleMenu from "../../components/Menu";
// import PacmanLoader from "react-spinners/PacmanLoader";
// import { css } from "@emotion/core";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;

class View extends Component {
    constructor(props) {
        super(props);

        const { link } = this.props.match.params;

        this.state = {
            link: link,
            numPages: null,
            pageNumber: 1,
            hidden: true,
            intervals: []
        }

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    };

    changePage = (isPrevious) => {
        const { pageNumber, numPages } = this.state;
        let nextPage = 0;
        //console.log('pageNumber: ', pageNumber);
        isPrevious ?
            pageNumber !== 0 ? nextPage = pageNumber - 1 : nextPage = pageNumber
        :
            pageNumber !== numPages ? nextPage = pageNumber + 1 : nextPage = pageNumber
            //console.log('nextPage: ', nextPage);
            //console.log('numPages: ', numPages);
        this.setState({ pageNumber: nextPage});
    }

    footerHandler = () => {
        let seconds = 3;
        let ref = this;
        let exhaust = 0;
        let exhaustLimit = seconds*1000;
        let intervalsRef = this.state.intervals;
        intervalsRef.forEach(clearInterval);
        this.setState({intervals: []});
        exhaust < exhaustLimit ? exhaust += seconds*1000 : exhaust = exhaust;
        if(this.state.hidden) {
            this.setState({hidden: false});
        }
        let i = setInterval(function() {
            !exhaust <= 0 ? exhaust-=1000 : exhaust=0;
            if(exhaust === 0) {
                ref.setState({hidden: true});
                clearInterval(i);
            }
        }, 1000);
        this.setState(prevState => ({
            intervals: [...prevState.intervals, i]
        }));
    }

    render() {

        const { pageNumber } = this.state;

        // const override = css`
        //     position: absolute;
        //     display: block;
        //     margin: 0 auto;
        //     border-color: red;
        //     z-index: 999;
        //     left: 30%;
        //     top: 50%;
        //     transform: translate(-50%, -50%);
        // `;
        const decoded = window.atob(this.state.link);
        //console.log(decoded);
        // this.setState({link: decoded});
        return (
            <div>
                <SimpleMenu />
                <Container>
                    <div className="contain" onClick={this.footerHandler}>
                        <Document  file={url + "/libraries/view/" + decoded} onLoadSuccess={this.onDocumentLoadSuccess} ><Page pageNumber={pageNumber} /></Document>
                        <div className={this.state.hidden ? "doc_foot hidden" : "doc_foot show"}>
                            {this.state.pageNumber > 1 && <button onClick={() => this.changePage(true)} id="previous">{String("<")}</button>}
                            <p>{this.state.pageNumber} de {this.state.numPages === null ? "0" : this.state.numPages}</p>
                            {this.state.pageNumber < this.state.numPages && <button onClick={() => this.changePage(false)} id="next">{String(">")}</button>}
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default View;
