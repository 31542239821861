import styled from "styled-components";
import backgroundLogin from "../../assets/background_signin.png";
import backgroundInputUsername from "../../assets/background_input_username.png";
import backgroundInputPassword from "../../assets/background_input_password.png";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  background-image: url(${backgroundLogin});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Form = styled.form`
    width: 100%;
    background: transparent;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .back {
        width: 50%;
        background-color: #f2f2f2;
        border-radius: 10px;
        margin-top: 5%;
        height: 50%;
        color: #259AD5;
        font-weight: bold;
        text-transform: uppercase;
    }

    .logo {
        width: 50%;
        margin: 10% 0 10px;
    }
    p {
        color: #ff3333;
        margin-bottom: 15px;
        padding: 10px;
        width: 100%;
        text-align: center;
    }
    input {
        margin-bottom: 8px;
        padding: 8px 10px 12px 10px;
        color: yellow;
        font-size: 15px;
        width: 70%;
        height: 100%;
        border: 0;
        &::placeholder {
            color: #999;
        }
        text-align: center;
        ::placeholder {
            color: yellow;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: yellow;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
            color: yellow;
        }
        outline: none;
    }

    .email {
        background:url(${backgroundInputPassword}) no-repeat right top;
        background-size: 100% 100%;
    }

    button {
        background-color: transparent;
        border: 0;
    }

    .btn-send {
        width: 70%;
        background:url(${backgroundInputUsername}) no-repeat right top;
        background-size: 100% 100%;
        color: yellow;
        outline: none;
        padding: .375rem .75rem;
    }

    input[type="button"]:focus,
    input[type="button"]:active {
        -moz-outline-style: none;
        outline:none;
        outline:0;
    }
`;
