import React, { Component } from "react";
import { getUser } from "../../services/auth";
import { FormStile, Container } from "./styles";
import SimpleMenu from "../../components/Menu";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import CurrentLocation from '../../components/Maps'
import BtnSubmit from "../../assets/background_btn_submit_2.png";
import api from "../../services/api";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Geocode from "react-geocode";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url, Tokenize } from "../../services/api";

Geocode.setApiKey("AIzaSyC9CdL5Ac4ujDvyG4u-FBXNQ82yHQfkkY4");

// set response language. Defaults to english.
Geocode.setLanguage("pt_BR");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("br");

// Enable or disable logs. Its optional.
Geocode.enableDebug();

class ComplaintAnonymous extends Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(getUser());
        //console.log('usuario: ', user);
        this.state = {
            loading: false,
            user: user,
            user_id: user.id,
            email: user.email,
            phone_number: '',
            message: '',
            file: '',
            latitude: -19.939584,
            longitude: -44.183070,
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            update_maps: false,
            preview: false,
            previewFile: '',
            showCam: false,
        }
    }

    onMarkerClick = (props, marker, e) =>
    this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
    });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    handleContactSubmit = async e => {
        this.setState({loading: true});
        //console.log('dados: ', this.state);
        const { user_id, phone_number, email, message, file } = this.state;
        try {
            await api.post("/contacts/add", { user_id, phone_number, email, message, file }).then(res=>{
                //console.log('retorno: ', res.data);
                this.setState({loading: false});
                if (file !== '') {
                    //console.log('fazer upload');
                    this.uploadFileData(res.data.contact.id);
                } else {
                    //console.log('não fazer upload');
                }
                //this.props.history.push("/");
                //alert.show('Denúncia Registrada com Sucesso');
                toast('Mensagem Enviada com Sucesso');
                this.setState({
                    message: '',
                    file: '',
                    preview: false,
                    previewFile: '',
                    showCam: false,
                    error: ''
                });
            }).catch(err=>{
                //console.log('erro retorno: ', err);
                this.setState({error: "Não foi possível enviar os dados. Tente novamente."});
                this.setState({loading: false});
                window.scrollTo(0, 0)
            });

        } catch (err) {
            //console.log(err);
            this.setState({ error: "Ocorreu um erro ao registrar os dados." });
            this.setState({loading: false});
            window.scrollTo(0, 0)
        }
    };

    onFileChange = async (event) => {
		await this.setState({
			file: event.target.files[0],
            previewFile: URL.createObjectURL(event.target.files[0]),
            preview: true,
		});
        //console.log('file:', this.state.file);
	}

    handleTakePhoto = async (dataUri) => {
        await this.setState({
            file: dataUri,
            previewFile: dataUri,
            preview: true,
            showCam: false,
        });
        //console.log('file: ', dataUri);
    }

    uploadFileData = (contact_id) => {
		//event.preventDefault();
		this.setState({msg: ''});

		let data = new FormData();
		data.append('file', this.state.file);
        data.append('id', contact_id);

		fetch(url + '/contacts/upload', {
			method: 'POST',
			body: data,
            headers: {'Accept': 'application/json;charset=UTF-8',
                        "Authorization": Tokenize()}
		}).then(response => {
            //console.log('retorno: ', response.data);
			this.setState({msg: "File successfully uploaded"});
		}).catch(err => {
            //console.log('retorno: ', err);
			this.setState({error: err});
		});

	}

    render() {

        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;
        return (
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: this.state.email,
                    phone_number: this.state.phone_number,
                    message: this.state.message,
                    file: '',
                }}
                validationSchema={Yup.object().shape({
                    message: Yup.string().required('Mensagem Obrigatória'),
                })}
                onSubmit={fields => {
                    //console.log('Campos: ', fields);
                    this.setState({email: fields.email});
                    this.setState({phone_number: fields.phone_number});
                    this.setState({message: fields.message});

                    this.handleContactSubmit();
                }}
            >
                {({errors, status, touched, submitForm}) => (
                    <div>
                        {this.state.loading && <div className="sweet-loading">
                            <PacmanLoader
                                css={override}
                                size={50}
                                color={"#123abc"}
                                loading={this.state.loading}
                            />
                        </div>}
                        {this.state.user && <SimpleMenu />}
                        <ToastContainer
                            hideProgressBar={true}
                            autoClose={false}
                            className='toast-container'
                            toastClassName="custom-toast"
                            progressClassName={css({
                                height: "2px"
                            })}
                        />
                        <Container>
                            <h1 className="title">CONTATO</h1>
                            <div className="contact-data">
                                <h4>Circo de Todo Mundo</h4>
                                <p>Telefone:</p>
                                <p>Escritório Contabilidade - <br />(31) 3271-4646</p>
                                <p>Lona Betim - (31) 3591-2903</p>
                                <p>Lona Nova Lima - (31) 3694-3630</p>
                                <p>Endereço: <br/>Rua Cícero Rabelo de Vasconcelos, 110 - <br/>Bairro Conjunto Olímpia Bueno Franco - CEP: 32671-730 - <br/>Betim - MG</p>

                            </div>
                            <CurrentLocation
                                centerAroundCurrentLocation
                                google={this.props.google}
                                currentLocation={
                                    {lat:this.state.latitude,
                                    lng:this.state.longitude}
                                }
                                mapClicked={this.mapClicked}
                                updateCurrentLocation={false}
                            >
                                <Marker onClick={this.onMarkerClick} name={'Circo de Todo Mundo'}
                                position={{lat:this.state.latitude,lng:this.state.longitude}} />
                                <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow}
                                    onClose={this.onClose}
                                >
                                    <div>
                                        <h4>{this.state.selectedPlace.name}</h4>
                                    </div>
                                </InfoWindow>
                            </CurrentLocation>

                            <div className="container">
                                <FormStile onSubmit={this.handleComplaintSubmit}>
                                    {this.state.error && <p dangerouslySetInnerHTML={{__html: this.state.error}}></p>}

                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="phone_number"
                                                type="text"
                                                placeholder="Telefone"
                                                className="background1"
                                            />
                                            <ErrorMessage name="phone_number" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <Field
                                                name="email"
                                                type="text"
                                                placeholder="E-mail"
                                                className="background2 text-white"
                                            />
                                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            <span className="label-textarea">Mensagem</span>
                                            <Field
                                                name="message"
                                                as="textarea"
                                                className="textarea"
                                                rows="5"
                                            />
                                            <ErrorMessage name="number" component="div" className="invalid-feedback" />
                                        </div>
                                    </div>
                                    <div className="form-group row w-100 justify-content-center">
                                        <div className="col-8 text-center align-self-center btn-capture-image">
                                            <label htmlFor="file">Selecione um Arquivo</label>
                                            <input onChange={this.onFileChange} type="file" className="collapse" id="file" accept="image/*"></input>
                                        </div>
                                    </div>
                                    <div className="form-group row w-100 justify-content-center">
                                        <div className="col-8 text-center align-self-center btn-capture-image">
                                            <label onClick={e => this.setState({showCam:true,preview:false})}>Tire uma foto</label>
                                        </div>
                                    </div>

                                    {this.state.showCam && <Camera
                                        onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                                    />}

                                    <div className="form-group row w-100">
                                        <div className="col-sm-12 text-center">
                                            {this.state.preview && <img src={this.state.previewFile} className="preview" alt="Imagem" />}
                                        </div>
                                    </div>

                                    <div className="form-group row w-100">
                                        <div className="col-sm-8 offset-sm-2 text-center">
                                            <img src={BtnSubmit} className="btn" onClick={submitForm} alt="Salvar" />
                                        </div>
                                    </div>

                                </FormStile>
                            </div>
                        </Container>
                    </div>
                )}
            </Formik>
        );
    }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyC9CdL5Ac4ujDvyG4u-FBXNQ82yHQfkkY4')
})(ComplaintAnonymous);
//export default withRouter(ComplaintAnonymous);
