import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container } from "./styles";
import SimpleMenu from "../../components/Menu";
import partnersImg from "../../assets/marcas_app_vertical.jpg";
import { getUser } from "../../services/auth";
import backgroundTerms from "../../assets/background_terms.jpg";

class Partners extends Component {
    constructor(props) {
        super(props);
        const user = JSON.parse(getUser());
        //console.log('usuario: ', user);
        this.state = {
            user: user
        }
    }

    render() {
        return (
            <div style={{backgroundImage: `url(${backgroundTerms})`, backgroundSize: "cover", backgroundPosition: "center"}}>
                {this.state.user && <SimpleMenu />}
                <Container>
                    <div className="partBox">
                        <img src={partnersImg} for="partner" />
                    </div>
                    <br></br>
                </Container>
            </div>
        );
    }
}

export default withRouter(Partners);
