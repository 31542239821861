import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import SimpleMenu from "../../components/Menu";
import UsersChat from "../../components/UsersChat";
import { Container } from "./styles";
import api from "../../services/api";
import PacmanLoader from "react-spinners/PacmanLoader";
import { css } from "@emotion/core";
import { getUser, setUserlist, getUserlist } from "../../services/auth";
import firebase from '../../firebase';

class Chat extends Component {
    constructor(props) {
        super(props);

        const user = JSON.parse(getUser());

        this.state = {
            loading: true,
            users: {},
            usersList: {},
            user: user
        }

        api.get("/users/usersChat/" + user.id).then(res=>{
            //console.log(res.data);
            this.state.usersList.notified = null;
            this.setState({
                loading: false,
                users: res.data.users,
                usersList: res.data.users
            });
            setUserlist(this.state.usersList);
        }).catch(err=>{
            //console.log('Erro ao buscar os dados de usuários');
            getUserlist();
        });

        this.listenUsers();
    }

    listenUsers() {
        let ref = this;
        const messagesDB = firebase.database().ref("messages/").child(this.state.user.id);
        messagesDB.orderByKey().limitToLast(1).on("child_added", snapshot => {
            let users = ref.state.usersList;
            {Object.keys(users).map((e, i) => {
                if(users[i].id === parseInt(snapshot.val().id_from)) {
                    if(!users[i].notified) {
                        users[i].notified = true;
                        ref.setState({
                            usersList: users
                        });
                        //console.log(ref.state.usersList[i]);
                    }
                }
                })
            }
            
        })
    }

    search = (e) => {

        const newList = this.state.users.filter(
            (elem, index, array) => {

                if (elem.username.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
                    //console.log('elemento existe: ', elem.username);
                    return elem;
                } else {
                    //console.log('elemento não existe: ', elem.username);
                    return false;
                }
            }
        );
        this.setState({
            usersList: newList
        });
        //console.log('lista: ', this.state.users);
        //console.log('nova lista: ', newList);
    }

    clearNotify(index) {
        const u = this.state.usersList;
        u[index].notified = false;

        this.setState({
            usersList: u
        })
    }

    render() {

        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;

        return (
            <div>
                <SimpleMenu />
                {this.state.loading && <div className="sweet-loading">
                    <PacmanLoader
                        css={override}
                        size={50}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>}
                <Container>
                    <div className="">
                        <h1 className="title">Chat</h1>
                        <div className="col-10 offset-1">
                            <input
                                type="text"
                                className="search"
                                placeholder="Filtrar"
                                onChange={this.search}
                            />
                        </div>
                    </div>
                    <div className="items">
                        <div className="text-center">
                            {Object.keys(this.state.usersList).map((e, i) => {
                                let u = this.state.usersList;
                                return <UsersChat key={i}
                                    item={this.state.usersList[i]}
                                    notifyDisplay={this.state.usersList[i].notified ? "block" : "none"}
                                    click={function(){u[i].notified=false;}}
                                />
                            })
                            }
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withRouter(Chat);
