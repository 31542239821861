import styled from "styled-components";
import backgroundChat from "../../assets/background_chat.jpg";
import BackgroundSearch from "../../assets/background_input_password.png";

export const Container = styled.div`
    justify-content: center;
    background-image: url(${backgroundChat});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    min-height: 100vh;
    padding-top: 60px;

    .title {
        width: 100%;
        background-size: 100% 100%;
        color: #fff;
        outline: none;
        padding: 0rem .8rem 0rem .8rem;
        font-size: 2rem;
        background-color: transparent;
        border: 0;
        font-family: 'Cooper Black';
    }

    .search {
        background-image: url(${BackgroundSearch});
        background-size: 100% 100%;
        background-color: transparent;
        margin-bottom: 5px;
        border: 0;
        color: yellow;
        &::placeholder {
          color: yellow;
        }
        text-align: center;
        ::placeholder {
            color: yellow;
            opacity: 1; /* Firefox */
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: yellow;
        }

        ::-ms-input-placeholder { /* Microsoft Edge */
         color: yellow;
        }
        outline: none;
    }
`;
