import React, {Component} from 'react';
// import { Link } from "react-router-dom";
import SimpleMenu from '../../../components/Menu/index.js';
import { getUser, setRank, getRank } from '../../../services/auth';
import api from "../../../services/api";
import { css } from "@emotion/core";
import PacmanLoader from "react-spinners/PacmanLoader";
import '../style.css';

const Header = () => <SimpleMenu />;

class Rank extends Component {

    constructor(props) {
        super(props);
        const user = JSON.parse(getUser());
        const gameid = this.props.match.params.gameId;

        const apiUrl = "/gamePoints/rank/" + user.id + "/" + gameid;

        this.state = {
            loading: true,
            user: user,
            gameId: gameid,
            userList: {}
        }

        api.get(apiUrl).then(res=>{
            this.setState({
                loading: false,
                userList: res.data.rank
            });
            setRank(this.state.gameId, JSON.stringify(this.state.userList));
        }).catch(err=>{
            //console.log('Erro ao buscar os dados de ranking');
            
            this.setState({
                loading: false,
                userList: JSON.parse(getRank(this.state.gameId))
            });
        });
    }

    render() {

        const override = css`
            position: absolute;
            display: block;
            margin: 0 auto;
            border-color: red;
            z-index: 999;
            left: 30%;
            top: 50%;
            transform: translate(-50%, -50%);
        `;

        return (
            <div>
                <Header />

                {this.state.loading && <div className="sweet-loading">
                    <PacmanLoader
                        css={override}
                        size={50}
                        color={"#123abc"}
                        loading={this.state.loading}
                    />
                </div>}

                <div className="body">
                    <div class="verticalDrawer">
                        <table id="rank">
                            <tr>
                                <th>Colocação</th>
                                <th>Usuário</th>
                                <th>Pontuação</th>
                            </tr>
                            {Object.keys(this.state.userList).map((e, i) => {

                                    return  <tr>
                                                <td>{this.state.userList[i].position}º</td>
                                                <td>{this.state.userList[i].username}</td>
                                                <td>{this.state.userList[i].points}</td>
                                            </tr>
                                })
                            }
                        </table>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default Rank;